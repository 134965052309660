/* Gabel Additional CSS */
.block-model .thumb.default {
    border: solid .1rem #eee;
  }
  .video-description a {
    color: #ff5252;
}  
  .text-desc {
    font-weight: 400;
    line-height: 20px;
    color: #36393D;
    font-size: 15px;
    padding: 15px;
    overflow-y: auto;
  }
  .text-desc h3 {
    font-size: 20px;
    line-height: 1.2;
    color: #D15600;
    margin: 0px 0 3px 0;
    font-weight: normal;
  }
  .text-desc h2 {
    font-size: 20px;
    line-height: 1.2;
    color: #D15600;
    margin: 0px 0 3px 0;
    font-weight: normal;
  }
  .logo img {
    width: 220px;
    max-width: 100%;
    max-height: 50px;
    height: 6rem;
  }
  .video-description {
    color: #212121;
    font-size: 14px;
    margin: 0 30px 2rem 0;
    display: inline-block;
  }
  /* gabel */
.title-studio {
  padding: 5px 15px;
  -webkit-transition: color .2s linear, background .2s linear;
  transition: color .2s linear, background .2s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 16px;
  font-weight: 400;
}
.title-studio .videos {
  float: right;
}
.item .duration {
  color: #bcbcbc;
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
  border-radius: 12px;
  background-color: #fff;
  position: absolute;
  left: 5px;
  padding: 4px 11px;
}
.search label {
  margin: 0;
}
  @media (max-width: 991.98px) { 
  .category-desc {
  
    max-height: 200px;
  }
  
  }
  @media (min-width: 1281px) {
    
      .list-models .item, .list-categories .item, .list-channels .item {
          width: calc(16% - 1px)!important;
      }
      
      
    }
    @media (min-width: 1025px) and (max-width: 1280px) {
    
      .list-models .item, .list-categories .item, .list-channels .item {
          width: calc(20% - 10px)!important;
      }
      
    }