.autocomplete-suggestions { border: 1px solid #999; background: #fff; overflow: auto; margin: 0 -60px 0 13px; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; cursor: pointer; }
.autocomplete-selected { background: #f0f0f0; }
.autocomplete-suggestions strong { font-weight: normal; color: #276fdb; border-bottom: 1px solid #276fdb; }
.autocomplete-group { padding: 5px; font-weight: bold;}
.autocomplete-group strong { display: block; }

/* NOTE */
/* .place ~ .item:nth-of-type such selection used 
in case of banner or sidebar is placed on one line with items */
/* NOTE */
@import "https://fonts.googleapis.com/css?family=Roboto:400,500,700&amp;subset=cyrillic";
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&family=K2D:wght@400;500;600;700;800&family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,300;1,500&display=swap');

@font-face {
  font-family: "icomoon";
  src: url("../images/fonts/icomoon.eot?nddhpi");
  src: url("../images/fonts/icomoon.eot?nddhpi#iefix")
      format("embedded-opentype"),
    url("../images/fonts/icomoon.ttf?nddhpi") format("truetype"),
    url("../images/fonts/icomoon.woff?nddhpi") format("woff"),
    url("../images/fonts/icomoon.svg?nddhpi#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
fieldset,
img {
  border: 0;
}
* {
  outline: none;
  text-decoration: none;
}
img,
object,
embed {
  vertical-align: bottom;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
p.text {
  font-size: 1.8rem;
  font-weight: 400;
  color: #212121;
  margin: 0 0 2.5rem;
  width: 100%;
  display: block;
  font-family: 'Balsamiq Sans', cursive;

}
q:before,
q:after {
  content: "";
}
a * {
  cursor: pointer;
}

html {
  height: 100%;
  background: #ffffff;
  font-size: 62.5%;
}
body {
  font: 400 14px/1.2 'Poppins', sans-serif;
  min-height: 100%;
  min-width: 320px;
  position: relative;
  color: #212121;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
a {
  color: #212121;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
}

.no-touch a:hover {
  color: #276fdb;
}

input,
select,
textarea {
  font-size: 13px;
  line-height: 1.2;
  font-family: "Roboto", Helvetica, sans-serif;
}
.container {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background: #f9f9f9;
}
.hidden {
  display: none;
}
/* .movable-top,
.movable-bottom {
	position: relative;
} */
.country {
  margin: 1px 3px 1px 0;
}
.page-error {
  padding: 10px;
  background: transparent;
  color: #e62117;
  border: 1px solid #e62117;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  margin: 20px 0 0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.icon:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-time:before {
  content: "\e91d";
}
.icon-categories:before {
  content: "\e903";
}
.icon-eye:before {
  content: "\e909";
}
.icon-comment:before {
  content: "\e914";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e90e";
}
.icon-flag:before {
  content: "\e90a";
}
.icon-album:before {
  content: "\e900";
}
.icon-share:before {
  content: "\e91a";
}
.icon-member:before {
  content: "\e913";
}
.icon-add:before {
  content: "\e917";
}
.icon-video:before {
  content: "\e91c";
}
.icon-categories:before {
  content: "\e903";
}
.icon-world:before {
  content: "\e908";
}
.icon-model:before {
  content: "\e915";
}
/* forms */
form .section-title {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  margin: 1rem 0;
  display: block;
}

form .section-title.expand,
form .section-title.collapse {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
form .section-title.expand:hover,
form .section-title.collapse:hover {
  color: #276fdb;
}
form .section-title.collapse:after,
form .section-title.expand:after {
  content: "\e901";
  display: block;
  position: absolute;
  margin-left: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  font-size: 6px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
form .section-title.collapse:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

form .row {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 2rem;
  line-height: 0;
}
.message-form form .row {
  width: 100%;
  max-width: 100%;
}

form .row:last-child {
  margin: 0;
}

form label {
  display: block;
  color: #212121;
  margin: 0 0 5px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 14px;
}
form label.field-label:after {
  content: ":";
}
form label.field-label.required:after {
  content: " (*):";
}
form .bottom label {
  font-size: 12px;
  color: #9f9f9f;
  margin: 0 0 7px;
}
form .bottom label.field-label {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
}
form .button-group .row {
  margin: 6px 0 0 0;
}
.button-group {
  margin: 0 0 2rem;
}
.two-sections .button-group {
  margin: 0;
}
form .button-group .row:first-child {
  margin: 0;
}
form .button-group .row label {
  margin: 0 0 5px;
  cursor: pointer;
}
form .radio,
form .checkbox {
  display: none;
}
form .radio + label,
form .radio + span,
form .checkbox + label,
form .checkbox + span {
  position: relative;
  padding-left: 24px !important;
  cursor: pointer;
}
form .radio + span {
  line-height: 1.2;
}
form .checkbox + label::before,
form .checkbox + span::before {
  content: "\e904";
  position: absolute;
  left: 0;
  top: -2px;
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e0dfdf;
  background-color: #fff;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  font-size: 11px;
  text-align: center;
  padding: 3px 0 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: background-color 0.3s, border 0.3s, color 0.3s;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
form .checkbox:checked + label::before,
form .checkbox:checked + span::before {
  background-color: #276fdb;
  border: 1px solid #276fdb;
}
form .radio + span::before,
form .radio + label::before {
	content: "";
	position: absolute;
	left: 0;
	top: -2px;
	z-index: 2;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	border: 2px solid #e0dfdf;
	background-color: #fff;
	padding: 3px 0 0;
	-webkit-transition: background-color 0.3s, border 0.3s, color 0.3s;
	transition: background-color 0.3s, border 0.3s, color 0.3s;
}
form .radio:checked + span::before,
form .radio:checked + label::before {
	background-color: #276fdb;
	border: 5px solid #e0dfdf;
}
form .submit {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 30px;
  cursor: pointer;
  border: 0;
  min-width: 140px;
  -webkit-appearance: none;
  background-color: #276fdb;
  border-radius: 20px;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.no-touch form .submit:hover {
  background-color: #e0dfdf;
  color: #212121;
}

.sidebar form .submit {
  min-width: 154px;
}
.margin-fix .bottom .submit.active,
.margin-fix .bottom .submit.right.active,
form .submit.active {
  background-color: #276fdb;
  color: #fff;
}
.margin-fix .bottom .submit:disabled,
.margin-fix .bottom .submit.right:disabled,
form .submit:disabled {
  pointer-events: none;
  color: rgba(33, 33, 33, 0.5);
}

form .textfield,
form .textarea,
form .selectbox,
form .file {
  border: 1px solid #e0dfdf;
  padding: 12px 20px;
  color: #212121;
  background: #fff;
  width: 100%;
  border-radius: 20px;
  height: 40px;
}
form .textarea {
  resize: none;
  min-height: 120px;
}
form .selectbox {
  cursor: pointer;
  padding: 10px 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0,0,51,32' width='10' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4.544c-0 0.005-0 0.011-0 0.017 0 1.277 0.486 2.44 1.284 3.315l-0.004-0.004 21.248 22.784c0.767 0.827 1.859 1.343 3.072 1.343s2.305-0.516 3.070-1.34l0.002-0.003 21.248-22.784c0.803-0.873 1.296-2.043 1.296-3.328s-0.492-2.455-1.299-3.332l0.003 0.004c-0.773-0.861-1.89-1.401-3.133-1.401-1.212 0-2.305 0.513-3.073 1.334l-0.002 0.002-18.112 19.456-18.112-19.456c-0.77-0.823-1.862-1.337-3.075-1.337-1.243 0-2.36 0.539-3.13 1.397l-0.003 0.004c-0.794 0.871-1.28 2.034-1.28 3.311 0 0.006 0 0.012 0 0.018v-0.001z'%3E%3C/path%3E%3C/svg%3E"), linear-gradient(to bottom, #ffffff 0%,#ffffff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 10px top 50%;
  background-size: auto;
}
form .selectbox::-ms-expand {
  display: none;
}
form .selectbox:disabled,
form .selectbox[aria-disabled=true] {
  color: graytext;
}
form .selectbox option {
  font-weight:normal;
}
form .date {
  font-size: 0;
}
form .date .selectbox {
  width: auto;
  margin-right: 10px;
}
form .file {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
}
form .file[disabled] {
  cursor: default;
}

form .textfield.error,
form .textarea.error,
form .selectbox.error,
form .file.error,
form fieldset.error .textfield,
form fieldset.error .textarea,
form fieldset.error .selectbox,
form fieldset.error .file {
  border: 2px solid #f30702;
}
form .file-control {
  position: relative;
  width: 100%;
  margin: 0 0 2rem;
}
form .file-control .textfield {
  padding-left: 24px;
}
form .file-control .button {
  position: absolute;
  left: 6px;
  top: 5px;
  border-radius: 15px;
  text-align: center;
  font-weight: 500;
  padding: 15px 10px;
  font-size: 13px;
  color: #212121;
  background-color: #e0dfdf;
}
form .file-control .button.disabled {
  color: #a2a2a2;
  background: #f1f1f1;
}
form .list-selector-popup {
  position: absolute;
  width: 100%;
  height: 160px;
  left: 0;
  bottom: 52px;
  border-radius: 5px;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  z-index: 10000;
  background-color: #f9f9f9;
}
form .list-selector-popup:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #f9f9f9;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  bottom: -5px;
  z-index: 10000;
}
form .list-selector-popup:before {
  position: absolute;
  content: "";
  width: 40px;
  height: 7px;
  background-color: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  z-index: 10001;
}
form .list-selector-popup .button-group {
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 8px;
  left: 8px;
  bottom: 30px;
  right: 8px;
}
form .list-selector-popup .button-group .group-title {
  margin: 0 5px 10px 0;
  padding: 3px;
  line-height: 1.2;
  background: #c8c8c8;
  font-weight: 800;
}
form .list-selector-popup .button-group .item {
  display: inline-block;
  width: 31%;
  padding: 3px 0 10px;
  vertical-align: top;
}

.fancybox-inner form .list-selector-popup .button-group .item {
  width: 49%;
}
.fancybox-outer,
.fancybox-inner {
  height: auto !important;
}
form .list-selector-popup .button-group .item label {
  color: #212121;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  padding: 0 0 0 25px;
}
form .list-selector-popup .filter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #c8c8c8;
  border: none;
}
form .list-selector-popup .filter input {
  width: 100%;
  height: 100%;
  padding: 8px 10px;
  border: none;
  background-color: #fff;
  color: #212121;
  font-weight: 400;
  line-height: 14px;
}
form .bottom {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
form .bottom a {
  color: #212121;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

form .bottom .links {
  width: 100%;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin: 0 0 2.7rem;
}

form .bottom .links p:first-child a {
  font-weight: bold;
  color: #276fdb;
}

form .bottom .links p:first-child a:hover {
  color: #212121;
}

form .bottom .submit.right {
  float: right;
  margin: 0 0 0 10px;
}

.no-touch form .bottom a:hover {
  color: #276fdb;
}
.no-touch form .bottom .submit.right:hover {
  background-color: #276fdb;
  color: #fff;
}

form .captcha-control {
  width: 100%;
}
form .captcha-control .image {
  margin: 0 0 2rem;
  overflow: hidden;
}
form .captcha-control .image img {
  display: block;
  width: 165px;
  height: 68px;
  margin: 0 1rem 1rem 0;
  float: left;
}
form .captcha-control .image .textfield {
  width: 150px;
}
form [data-name="code"] {
  margin: 0 0 2rem;
  min-height: 78px;
}
form .success {
  margin: 1rem;
  padding: 2.5rem 2rem;
  background: #276fdb;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 1.5rem;
  width: 100%;
  text-align: center;
}
.fancybox-outer form .success {
  width: auto;
  margin: 3rem;
}

form .generic-error {
  text-align: center;
  padding: 10px;
  color: #f30702;
  border: 2px solid #f30702;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  margin: 0 0 10px;
}
form .info-message {
  display: inline-block;
  position: relative;
  margin: 0 1rem 1rem 1rem;
  padding: 1rem;
  font-size: 14px;
  background: #e0dfdf;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
form .info-message:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #e0dfdf;
  left: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -5px;
}
form .info-message .bottom {
  display: block;
  padding: 1rem 0 0;
}
form .info-message .submit {
  font-size: 12px;
  min-width: auto;
  margin: 5px 5px 0 0;
}

form .field-error {
  display: none;
  padding: 5px 0 0 0;
  color: #f30702;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}

form .field-error label {
  margin: 0;
}

form .smileys-support {
  position: relative;
}
form .smileys-support .textarea {
  border-radius: 0 0 20px 20px;
}
form .smileys-support .smileys-bar {
  background: #dfdfdf;
  padding: 2px 10px 4px;
  cursor: default;
  font-size: 0;
  border-radius: 20px 20px 0 0;
}
form .smileys-support img {
  cursor: pointer;
  margin-right: 5px;
}
form .smileys-support img:last-child {
  margin: 0;
}
form .progressbar {
  position: absolute;
  z-index: 990;
  width: 60%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 5.0rem;
  border: 0.2rem solid #276fdb;
  padding: 0.8rem;
  border-radius: 2.4rem;
  background: #fff;
}
form .progressbar .text {
  position: absolute;
  width: 100%;
  top: 1rem;
  height: 3rem;
  font-size: 2.2rem;
  line-height: 3rem;
  text-align: center;
}
form .progressbar.half-done .text {
  color: #ffffff;
}
form .progressbar .progress {
  width: 0;
  height: 3rem;
  background: #276fdb;
  border-radius: 2rem;
}
/* toggle button */
.toggle-button {
  color: #212121;
  border: 1px solid #e0dfdf;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 2.7rem 9px 2.7rem 2.9rem;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: background-color 0.3s, color 0.3s, border 0.3s;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  cursor: pointer;
}
.toggle-button * {
  cursor: pointer;
}

.toggle-button input::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #d4d4d4;
  background-color: #ffffff;
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: border 0.3s, background-color 0.3s;
  transition: border 0.3s, background-color 0.3s;
}

.toggle-button.inversed.active input::before {
  border: 5px solid #ffffff;
  background-color: #276fdb;
}

.toggle-button.active {
  color: #ffffff;
  background-color: #276fdb;
}
.toggle-button.inversed {
  border: 1px solid #e4e4e4;
  background: #f1f1f1;
}
.toggle-button.inversed.active {
  color: #ffffff;
  border: 1px solid #276fdb;
  background-color: #276fdb;
}
.toggle-button.inversed.active label {
  color: #ffffff;
}
.toggle-button.done {
  pointer-events: none;
}
.toggle-button.done *,
.toggle-button.disabled * {
  cursor: default;
}
/* top links */
.top-links {
  background: #000;
  border-bottom: 2px solid #276fdb;
  padding: 6px 20px;
  font-size: 12px;
}
.top-links .center-hold {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.member-links {
  margin-left: auto;
}
.network ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.network li {
  margin-right: 20px;
}
.network li:last-child {
  margin: 0;
}
.network a {
  color: #fff;
  display: block;
  position: relative;
  padding-left: 14px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.network a::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  z-index: 1;
  background-color: #276fdb;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.network a:hover {
  color: #276fdb;
}
.network .more {
  display: none;
}

.member-links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.member-links li {
  padding: 0 20px 0 0;
  color: #fff;
}
.btn-icon {
  position: relative;
  padding-left: 39px;
}
.member-links .btn-icon {
  padding-left: 34px;
}
.btn-icon:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.login-btn:before {
  content: "\e913";
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
}
.signup-btn:before {
  content: "\e90f";
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
}
.logout-btn:before {
  content: "\e916";
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
}
.member-links a {
  color: #ffffff;
  background-color: transparent;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  line-height: 30px;
  display: block;
  display: block;
  margin: 0 -20px 0 0;
  border-radius: 15px;
  padding: 0 15px;
}
.member-links a.disabled {
  color: #fff;
}
.no-touch .member-links a:hover,
.no-touch .member-links a.disabled:hover {
  background-color: #276fdb;
  color: #fff;
}

/* header */
.header {
  z-index: 20;
  padding: 20px;
  background-color: #fff;
}
.header-inner {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.logo {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
}
.logo a {
  display: block;
}
.search {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 0 0 100px;
}
.search form {
  -webkit-box-flex: 0;
  -ms-flex: 1 1;
  flex: 1 1;
  position: relative;
  padding: 0 10px 0 0;
  border-radius: 20px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.08);
}

.search .search-text input {
  width: 100%;
  border: 0;
  background: none;
  border-radius: 5px;
  background-color: #212121;
  color: #ffffff;
  font-size: 13px;
  padding: 13px 60px 13px 20px;
}

.search-text input::-webkit-input-placeholder {
  color: #ffffff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.search-text input:-ms-input-placeholder {
  color: #ffffff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.search-text input::-ms-input-placeholder {
  color: #ffffff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.search-text input::placeholder {
  color: #ffffff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.search-text input:focus::-webkit-input-placeholder {
  color: transparent;
}
.search-text input:focus:-ms-input-placeholder {
  color: transparent;
}
.search-text input:focus::-ms-input-placeholder {
  color: transparent;
}
.search-text input:focus::placeholder {
  color: transparent;
}
.search .search-text input:focus {
  outline: none;
}
.search .search-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60px;
  background: #276fdb url("../images/search.svg") center no-repeat;
  text-indent: -1000px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.no-touch .search .search-button:hover {
  background-color: #1554b2;
}
/* navigation */
.header + nav {
  border: 1px solid #dfe0e1;
  border-left: 0;
  border-right: 0;
  background-color: #fff;
}
.navigation {
  position: relative;
}
.navigation .button {
  display: none;
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  background: transparent;
  height: 38px;
  cursor: pointer;
}
.navigation .button .icon {
  display: inline-block;
  height: 20px;
  width: 29px;
  position: relative;
}
.navigation .button .ico-bar {
  background: #000;
  height: 4px;
  width: 100%;
  position: absolute;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  left: 0;
}
.navigation .button .ico-bar:first-child {
  top: 0;
}
.navigation .button .ico-bar:nth-child(2) {
  top: 8px;
}
.navigation .button .ico-bar:nth-child(3) {
  top: 16px;
}
.navigation.open .button .ico-bar {
  background: #276fdb;
  left: 4px;
}
.navigation.open .button .ico-bar:first-child {
  top: -2px;
  -webkit-transform: rotate(45deg) translate(5px, 9px);
  transform: rotate(45deg) translate(5px, 9px);
}
.navigation.open .button .ico-bar:nth-child(3) {
  top: 14px;
  -webkit-transform: rotate(-45deg) translate(2px, -6px);
  transform: rotate(-45deg) translate(2px, -6px);
}
.navigation.open .button .ico-bar:nth-child(2) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1260px;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
}
.navigation .primary {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.navigation .primary li:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.navigation .primary a {
  font-weight: 600;
  display: block;
  padding: 17px 4px;
  text-transform: uppercase;
  position: relative;
  color: #212121;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}
.navigation .primary a:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-color: #276fdb;
  height: 3px;
  width: 0;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.no-touch .navigation .primary a:hover::before {
  width: 100%;
}
.no-touch .navigation .primary .highlight.drop a:hover::before {
  width: auto;
}
.no-touch .navigation .primary a:hover {
  color: #276fdb;
}

.navigation .primary .selected a {
  color: #276fdb;
  pointer-events: none;
}
#item1 {
  position: relative;
  font-size: 0;
  height: 100%;
}
#item1::after {
  position: absolute;
  content: "\e90d";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.navigation .primary .selected a::before {
  width: 100%;
}
.navigation .primary .drop {
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.navigation .primary .drop > a {
  position: relative;
  background-color: #e0dfdf;
  border-radius: 20px;
  -webkit-transition: background-color 0.3s, border-radius 0.3s;
  transition: background-color 0.3s, border-radius 0.3s;
  padding: 12px 38px 12px 54px;
}

.navigation .primary .drop > a:after {
  position: absolute;
  z-index: 1;
  content: "\e901";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 5px;
  top: calc(50% - 1px);
  right: 20px;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(-50%) rotate(0);
  transform: translateY(-50%) rotate(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.navigation .primary .drop > a:before {
  position: absolute;
  z-index: 1;
  content: "\e91b";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto;
  height: auto;
  background: transparent;
  font-size: 20px;
  top: calc(50% - 1px);
  bottom: inherit;
  left: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navigation .primary ul {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s,
    -webkit-transform 0.3s;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
}

.navigation .primary ul li {
  display: block;
}
.navigation .primary ul li a {
  background-color: #e0dfdf;
  color: #212121;
  padding: 12px 5px;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
.navigation .primary ul li a:before {
  display: none;
}
.navigation .primary .drop:focus ul,
.navigation .primary .drop:hover ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.navigation .primary .drop:hover > a {
  border-radius: 20px 20px 0 0;
  background-color: #276fdb;
  color: #fff;
}
.navigation .primary .drop:hover > a::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.no-touch .navigation .primary ul li a:hover {
  background-color: #276fdb;
  color: #fff;
}
.navigation .primary ul li a {
  padding: 9px 5px;
}

.navigation .secondary li:first-child {
  border-left: none;
}
.navigation .secondary a {
  text-decoration: none;
  padding: 17px 6px;
  position: relative;
  display: block;
  white-space: nowrap;
}
.navigation .secondary a:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-color: #276fdb;
  height: 3px;
  width: 0;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.no-touch .navigation .secondary a:hover {
  color: #276fdb;
}
.no-touch .navigation .secondary a:hover::before {
  width: 100%;
}

/* footer */
.footer {
  width: 100%;
}
.footer .footer-wrap {
  max-width: 1235px;
  padding: 3rem 5px;
  margin: 0 auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #9f9f9f;
  line-height: 18px;
}
.footer a {
  color: #ffffff;
  text-decoration: underline;
}
.footer a:hover {
  color: #ffffff;
}
.footer .nav {
  width: 100%;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 3rem;
}
.footer .nav li {
  margin: 0 10px 0 0;
}
.footer .nav li:last-child {
  margin: 0;
}
.footer .nav a {
  display: block;
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  border-radius: 15px;
  padding: 6px 2rem;
  text-transform: uppercase;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}

.no-touch .footer .nav a:hover {
  color: #fff;
  background-color: #276fdb;
}

.footer .copyright {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.footer .copyright a {
  color: #9f9f9f;
  padding: 0 4px;
  text-decoration: none;
}
.footer .copyright br {
  display: none;
}
.footer .txt {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin: 0 0 15px;
}
.box {
  margin: 0 0 3rem;
  width: 100%;
}
.box.message {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 400px;
}
/* headings *****/
.headline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 15px;
  padding: 3rem 0 0;
  width: 100%;
}
h1,
h2 {
  color: #212121;
  font-size: 2.4rem;
  font-weight: 500;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}
h1 a,
h2 a {
	text-decoration: underline;
}

.sort {
  position: relative;
  border-radius: 15px;
  background-color: #e0dfdf;
  color: #212121;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 8px 10px;
  margin: 0 15px 0 0;
  min-width: 140px;
  -webkit-transition: background-color 0.3s, border-radius 0.3s;
  transition: background-color 0.3s, border-radius 0.3s;
}
.sort:last-child {
  margin: 0;
}
.sort:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(0);
  transform: translateY(-50%) rotate(0);
  z-index: 1;
  font-size: 6px;
  content: "\e901";
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.sort:focus,
.sort:hover {
  border-radius: 15px 15px 0 0;
  background-color: #276fdb;
  color: #fff;
}
.sort:hover:before {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.sort .icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 15px;
}
.sort .type-sort:before {
  content: "\e921";
}
.sort [class*="videos"]:before,
.sort .type-video:before {
  content: "\e91c";
}
.sort .type-fav:before {
  content: "\e90c";
}
.sort .type-visibility:before {
  content: "\e909";
}
.sort .type-edit:before {
  content: "\e91e";
}
.sort [class*="release_year"]:before,
.sort [class*="post_date"]:before {
  content: "\e902";
}
.sort [class*="duration"]:before {
  content: "\e91d";
}
.sort [class*="dir"]:before,
.sort [class*="title"]:before {
  content: "\e91e";
}
.sort [class*="popularity"]:before,
.sort [class*="viewed"]:before,
.sort [class*="watched"]:before,
.sort [class*="last_time"]:before {
  content: "\e909";
}
.sort [class*="albums"]:before {
  content: "\e900";
}
.sort [class*="popularity"] + strong,
.sort [class*="viewed"] + strong,
.sort [class*="last_time"] + strong {
  padding: 0 17px 0 30px;
}
.sort [class*="dir"]:before,
.sort [class*="title"]:before {
  content: "\e91e";
}
.sort [class*="rating"]:before {
  content: "\e90b";
}
.icon type-sort last_content_date,
.sort [class*="most_favourited"]:before {
  content: "\e90c";
}
.sort [class*="date"]:before {
  content: "\e902";
}
.sort [class*="comments"]:before,
.sort [class*="most_commented"]:before {
  content: "\e914";
}
.sort strong {
  padding: 0 17px 0 20px;
  display: block;
  text-align: center;
}
.icon.type-visibility + strong {
  padding: 0 17px 0 25px;
}
.sort .icon.total_albums + strong,
.sort .icon.type-video + strong {
  padding: 0 17px 0 25px;
}
.sort ul {
  position: absolute;
  top: 100%;
  width: 100%;
  right: 0;
  z-index: 100;
  overflow: hidden;
  background: #e0dfdf;
  border-radius: 0 0 15px 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.sort ul li:first-child {
  margin-top: 5px;
}
.no-touch .sort ul li:first-child {
  margin-top: 0;
}
.sort:hover ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.sort ul a {
  display: block;
  padding: 8px 5px;
  text-align: center;
  background: transparent;
  -webkit-transition: background 0.3s, color 0.3s;
  transition: background 0.3s, color 0.3s;
}

.no-touch .sort ul a:hover {
  color: #ffffff;
  background: #276fdb;
  cursor: pointer;
}

.headline .button,
.headline .more {
  padding: 7px 15px;
  color: #fff;
  background-color: #276fdb;
  border-radius: 15px;
  margin: 2px 0 2px 3px;
}
.no-touch .headline .more:hover,
.no-touch .headline .button:hover {
  background-color: #c6c6c6;
  color: #212121;
}
.headline .button.icon {
  position: relative;
  padding: 7px 15px 7px 25px;
}
.headline .button.done {
  padding: 7px 15px;
}
.headline .button.icon:before {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.headline .button.done:before {
  content: "";
}
.headline .more {
  position: relative;
}
.headline .button.done,
.headline .button.disabled {
  pointer-events: none;
}
.headline .button.done *,
.headline .button.disabled * {
  cursor: default;
}
.headline .button span {
  padding: 0 9px;
  -webkit-transition: padding 0.3s;
  transition: padding 0.3s;
}
.toggle-button.done span,
.headline .button.done span {
  position: relative;
  padding: 0 9px 0 23px;
}
.toggle-button.done span:before,
.headline .button.done span:before {
  position: absolute;
  content: "\e904";
  font-size: 12px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.headline .more span {
  font-weight: bold;
  padding: 0 25px 0 9px;
}
.headline .more:after {
  content: "";
  border-left: 4px solid #222222;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  display: block;
  position: absolute;
  top: 10px;
  right: 8px;
}
.headline .more:hover:after {
  border-left: 4px solid #ffffff;
}
.headline .button-info {
  padding: 7px 15px 7px 30px;
  border-radius: 3px;
  background-color: #e0dfdf;
  border-radius: 15px;
  margin: 0 0 0 3px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  border-radius: 0 15px 15px 0;
  margin: 2px 0;
  position: relative;
}
.headline .button-info:before {
  content: "\e913";
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.headline .button-info + .button {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  border-radius: 15px 0 0 15px;
}

.empty-content {
  margin: 0 0 3rem;
  font-size: 14px;
  line-height: 1.2;
  padding: 10px 0 0 10px;
}
.empty-content h2 {
  font-size: 1.8rem;
  margin: 0 0 10px;
}
.empty-content a {
  text-decoration: underline;
}
.empty-content p {
  margin: 0 0 10px;
  line-height: 20px;
}
.empty-content ul,
.empty-content ol {
  padding-left: 20px;
}
.empty-content li {
  list-style: disc inside;
  line-height: 20px;
  margin-bottom: 10px;
}
.empty-content ol li {
  list-style: decimal inside;
}
/* tags cloud *****/
.tags-cloud {
  font-family: 'K2D', sans-serif;
  margin: 0 0 3rem;
  padding: 3rem 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.tags-cloud a {
  padding: 0.8rem 1rem;
  background-color: transparent;
  border-radius: 15px;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  text-transform: uppercase;
}

.no-touch .tags-cloud a:hover {
  color: #fff;
  background-color: #276fdb;
}

.tags-cloud .all {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  color: #212121;
  background-color: #e0dfdf;
  padding: 12px 20px;
  border-radius: 20px;
  width: 100%;
  margin: 10px 0 0;
}
/* search cloud *****/
.search-cloud {
  margin-top: 2rem;
  text-align: center;
}
/* advertisement boxes *****/
.spot img,
.spot embed,
.spot object,
.spot iframe {
  vertical-align: bottom;
  width: 100%;
  height: 100%;
}
/* top ad */
div.top {
  width: 100%;
  margin: 0 auto;
  padding: 5px 0 0;
  height: 100px;
}
div.top a {
  display: block;
  width: 100%;
}
div.top img {
  width: 100%;
  height: auto;
}
/* list ads */
.place {
  float: right;
  width: 320px;
  overflow: hidden;
  background: transparent;
  position: relative;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  padding: 66px 0;
  font-size: 0;
}

.sidebar + .main-container .list-albums .place {
  padding: 102px 0;
}

.place ~ .item:nth-of-type(-n + 7) {
  width: calc((100% - 362px) / 3);
}
.margin-fix .place {
  margin-top: 10px;
}
.place .spot {
  overflow: hidden;
  margin: 0 0 10px;
}
.place .spot:last-child {
  margin: 0;
}
.place .spot a {
  width: 300px;
  height: 250px;
  background: #c8c8c8;
  overflow: hidden;
  display: block;
}
.list-albums .spot,
.list-videos .spot,
.list-playlists .spot {
  display: inline-block;
  width: 300px;
  height: 250px;
}

/* bottom adv */
.footer-margin {
  background-color: #f9f9f9;
  padding: 2rem 0;
}
.bottom-adv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  width: 100%;
}
.bottom-adv .spot {
  display: inline-block;
  vertical-align: top;
  width: 300px;
  height: 250px;
  overflow: hidden;
  margin: 1px;
}
.bottom-adv .spot a {
  width: 300px;
  height: 250px;
  background: #c8c8c8;
  overflow: hidden;
  display: block;
}
/* lists *****/
.list-comments,
.list-subscriptions,
.list-playlists,
.list-members,
.list-messages,
.list-models,
.list-sponsors,
.list-channels,
.list-categories,
.list-tags,
.list-albums,
.list-albums-images,
.list-videos-screenshots {
  width: 100%;
}
.margin-fix {
  margin: -10px 0 0 -10px;
  font-size: 0;
  line-height: 0;
}
.margin-fix::after .margin-fix::before {
  content: "";
  display: table;
  width: 100%;
  overflow: hidden;
  clear: both;
}
.list-albums-images .margin-fix {
  margin-bottom: 20px;
}
.list-comments .margin-fix {
  margin: 0;
}
.list-tags .margin-fix {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}
.list-tags .margin-fix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.list-tags .margin-fix .item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}
.margin-fix .bottom {
  margin: 0;
  max-width: 100%;
  width: 100%;
  padding: 2rem 0 0 10px;
}
.margin-fix .bottom .submit,
.margin-fix .bottom .submit.right {
  margin: 5px 5px 0 0;
  background-color: #e0dfdf;
  color: #212121;
}
.no-touch .margin-fix .bottom .submit:hover,
.no-touch .margin-fix .bottom .submit.right:hover {
  background-color: #276fdb;
  color: #fff;
}
.margin-fix .bottom .submit:last-child,
.margin-fix .bottom .submit.right:last-child {
  margin: 5px 0 0;
}
.margin-fix .generic-error {
  margin: 15px 0 0 15px;
}
.no-thumb {
  background: url("../images/kvs.svg") no-repeat center;
}
.list-members .item {
  width: calc(20% - 10px);
  display: inline-block;
  text-align: center;
  margin: 10px 0 2.5rem 10px;
  /* padding: 0 5px; */
  vertical-align: top;
}

.list-playlists .item,
.list-models .item,
.list-sponsors .item,
.list-channels .item,
.list-categories .item,
.list-albums .item,
.list-albums-images .item,
.list-videos .item,
.list-videos-screenshots .item {
  display: inline-block;
  text-align: left;
  background: transparent;
  vertical-align: top;
  cursor: pointer;
  margin: 10px 0 0 10px;
  width: calc(25% - 10px);
  cursor: pointer;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  border-radius: 0 0 5px 5px;
  background-color: #ffffff;
}

.sidebar + .main-container .place ~ .item:nth-of-type(-n + 5) {
  width: calc((100% - 352px) / 2);
}
.sidebar + .main-container .list-albums .place ~ .item:nth-of-type(-n + 7) {
  width: calc((100% - 362px) / 3);
}
.sidebar + .main-container .list-videos .item {
  width: calc(33.33% - 10px);
}
.list-members .item a {
  display: block;
}
.list-members .item.unread a {
  padding-bottom: 20px;
}
.list-playlists .item a,
.list-sponsors .item,
.list-channels .item a,
.list-categories .item,
.list-models .item,
.list-albums .item a,
.list-videos .item a {
  display: block;

  font-size: 12px;
  line-height: 1.2;
}
.list-tags .item a {
  display: inline-block;
  padding: 6px;
}
.list-channels .item,
.list-models .item,
.list-categories .item {
  display: inline-block;
  margin: 10px 0 0 10px;
  padding: 0;
  width: calc(20% - 60px);
}
.list-sponsors .item{
  display: inline-block;
  margin: 10px 0 0 10px;
  padding: 0;  
  width: calc(25% - 10px);
}
.item-control {
  border-top: 1px solid #e0dfdf;
  padding: 10px;
  cursor: default;
}

.list-members .item.unread .img {
  border: 2px solid #ee0000;
}

.item .img {
  position: relative;
  background-color: #e0dfdf;
  overflow: hidden;
}
.list-models .img,
.list-channels .img,
.list-categories .img {
  padding-bottom: 133.44%;
}
.list-sponsors .img{
  padding-bottom: 56%;
  border-radius: 10px;
}
.list-videos-screenshots .img,
.list-videos .img {
  width: 100%;
  padding-bottom: 56.25%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}
.list-videos .is-hd {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 3;
  opacity: 0.8;
  background-color: #ee0000;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.33px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 4px 8px;
}
.list-playlists .img {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}
.list-videos .img.preview-loading:after {
  display: block;
  position: absolute;
  content: "";
  width: 21px;
  height: 21px;
  background: url("../images/loader.gif") no-repeat;
  bottom: 5px;
  right: 5px;
  z-index: 1;
  opacity: 0.8;
}
.list-members .img {
  display: inline-block;
  width: 10rem;
  height: 10rem;
  position: relative;
  border-radius: 50%;
  overflow: visible;
}
.list-albums .img {
  padding-bottom: 133.44%;
}
.list-albums-images .img {
  width: 100%;
  padding-bottom: 75%;
  text-align: center;
}
.item .img img:first-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.list-members .item .img img:first-child {
  border-radius: 50%;
}

.item .img .no-thumb {
  display: block;
  height: 100%;
  background-size: 6rem;
}
.item.disabled .img {
  background-color: #212121;
}
.item.disabled .img img {
  opacity: 0.3;
}
.list-albums-images .img .no-thumb,
.list-models .img .no-thumb,
.list-sponsors .img .no-thumb,
.list-channels .img .no-thumb,
.list-categories .img .no-thumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  font-size: 0;
}

.list-playlists .img img.video2,
.list-playlists .img img.video3,
.list-playlists .img img.video4,
.list-playlists .img img.video5 {
  position: absolute;
  width: 7rem;
  height: 4rem;
  border: 2px solid rgba(255, 255, 255, 0.5);
  z-index: 2;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
}

.no-touch .list-playlists .item:hover .img img {
  opacity: 1;
}
.no-touch .list-playlists .item .img .video3:hover,
.no-touch .list-playlists .item .img .video4:hover,
.no-touch .list-playlists .item .img .video2:hover {
  z-index: 3;
  border: 2px solid rgba(39, 111, 219, 0.5);
}

.list-playlists .img img.video2 {
  right: 25px;
  top: 5px;
}
.list-playlists .img img.video3 {
  right: 15px;
  top: 15px;
}
.list-playlists .img img.video4 {
  right: 5px;
  top: 25px;
}
.list-playlists .img .no-thumb {
  /* line-height: 135px; */
  position: absolute;
  height: 67px;
  font-size: 0;
  width: 60px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5;
}
.item .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: block;
  color: #212121;
  padding: 1rem 1rem 0;
  margin: 0 0 9px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  overflow: hidden;
}
.list-playlists .item .title,
.list-albums .item .title,
.list-videos .item .title {
  height: 45px;
}
.no-touch .list-subscriptions .item:hover .title {
  color: #212121;
}
.no-touch .item:hover .title {
  color: #276fdb;
}

.item .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px 8px;
  color: #bcbcbc;
  font-size: 12px;
  line-height: 1.2;
}
.list-members .item .wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.list-members .item .wrap .views {
  display: none;
}
.list-members .item .wrap .added {
  margin: 0 5px 0;
}
.item .wrap div {
  position: relative;
  padding-left: 20px;
}
.item .wrap .albums {
  padding-left: 25px;
}
.item .wrap div:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 15px;
}
.item .wrap .duration:before {
  content: "\e91d";
}
.item .wrap .rating::before {
  content: "\e90b";
}
.item .wrap .albums::before {
  content: "\e900";
}
.item .wrap .videos::before {
  content: "\e91c";
}
.item .wrap .photos::before {
  content: "\e900";
}
.item .wrap .photos {
  padding-left: 25px;
}
.item .wrap .rating.negative::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  top: calc(50% - 7px);
}
.item .wrap .added::before {
  content: "\e902";
}
.item .wrap .views {
  padding-left: 28px;
}
.item .wrap .views::before {
  content: "\e909";
}
.item.disabled .title,
.item.processing .title {
  color: #276fdb;
}
.item.error .title {
  color: #e62117;
}
.list-tags .item .title {
  height: auto;
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  margin: 0;
  padding: 20px 0 15px 10px;
}
.list-subscriptions .item .title {
  min-height: auto;
  text-decoration: underline;
  display: block;
  padding: 1.6rem 10px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #212121;
}

.no-touch .list-subscriptions .item .title:hover {
  color: #276fdb;
}
.no-touch .item:hover .title {
  color: #276fdb;
}
.no-touch .list-tags .item:hover .title {
  color: inherit;
}

.item.unread .views {
  font-weight: bold;
}
.item .user {
  height: 14px;
  overflow: hidden;
  white-space: nowrap;
}
.item .rating {
  position: relative;
}
.item .positive {
  color: #276fdb;
}
.item .negative {
  color: #f30702;
}
.item .is_hd {
  position: absolute;
  z-index: 2;
  right: 5px;
  top: 5px;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.33px;
  opacity: 0.8;
  background-color: #ee0000;
  border-radius: 12px;
  padding: 5px 8px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(150%);
  transform: translateX(150%);
  -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s,
    -webkit-transform 0.3s;
}
.item .ico-fav-0 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  -webkit-transform: translateX(-150%);
  transform: translateX(-150%);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.no-touch .item .ico-fav-0:hover {
  background-color: #276fdb;
}
.item .ico-fav-0::before {
  content: "\e90c";
}
.item .ico-fav-1 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  left: 48px;
  z-index: 10;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}
.item .ico-fav-1::before {
  content: "\e91d";
}
.no-touch .item .ico-fav-1:hover {
  background-color: #276fdb;
}
.item .touch-preview,
.item [class*="ico-fav"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 19px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s,
    -webkit-transform 0.3s;
}
.item .touch-preview {
  display: block;
  opacity: 0.6;
  visibility: visible;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 20;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.item .touch-preview::before {
  content: "\e923";
}
.no-touch .item:hover .is_hd,
.no-touch .item:hover [class*="ico-fav"] {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.no-touch .item .ico-fav-0 {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-150%);
  transform: translateX(-150%);
}
.no-touch .item .ico-fav-1 {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}
.item .ico-fav-0.fixed,
.item .ico-fav-1.fixed {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.line-disabled,
.line-error,
.line-processing {
  position: absolute;
  top: 50%;
  left: 15%;
  width: 70%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  background: #276fdb;
  padding: 5px 0;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  border-radius: 5px;
}
.line-error {
  color: #ffffff;
}
.line-premium,
.line-private {
  position: absolute;
  z-index: 2;
  bottom: 5px;
  right: 5px;
  text-align: center;
  border-radius: 12px;
  background-color: rgba(45, 45, 45, 0.6);
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  text-transform: uppercase;
  padding: 2px 9px;
}
.line-premium [class*="ico"],
.line-private [class*="ico"] {
  padding: 2px 0 0 20px;
  position: relative;
}
.line-premium [class*="ico"]:before,
.line-private [class*="ico"]:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  content: "\e918";
  color: #276fdb;
}
.line-private [class*="ico"] {
  padding: 2px 0 0 15px;
}
.line-private [class*="ico"]:before {
  content: "\e912";
}
.list-members .unread-notification {
  position: absolute;
  left: calc(100% - 5px);
  white-space: nowrap;
  top: -3px;
  font-size: 0;
  line-height: 1.2;
  color: #ee0000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.list-members .unread-notification:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e914";
  margin: 0 5px -2px 0;
  font-size: 15px;
}
.item-control .item-control-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.item-control .toggle-button {
  background-color: #e0dfdf;
  border-radius: 15px;
  border: none;
  padding: 8px 10px;
  font-size: 11px !important;
  line-height: 1.2;
  color: #212121;
  margin: 0 3px 0 0;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
.item-control .toggle-button:last-child {
  margin: 0;
}
.no-touch .item-control .toggle-button[data-action="delete"]:hover {
  background-color: #ee0000;
  color: #fff;
}
.item-control .toggle-button.active,
.no-touch .item-control .toggle-button:hover {
  background-color: #276fdb;
  color: #ffffff;
}
.item-control .toggle-button .radio + span::before,
.item-control .toggle-button .radio + label::before {
  top: -3px;
}
.item-control .toggle-button .checkbox + span::before,
.item-control .toggle-button .checkbox + label::before {
  top: -3px;
}

.item-control .toggle-button[data-action="select"] {
  background: 0;
  position: relative;
  padding: 8px 10px;
  margin: 0 1rem 0 0;
}
.item-control .toggle-button[data-action="select"]:after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e0dfdf;
  background-color: #ffffff;
  padding: 0;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.item-control .toggle-button[data-action="select"]:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 3px;
  z-index: 3;
  font-size: 11px;
  color: #fff;
  content: "\e904";
}
.list-albums-images .item-control .toggle-button[data-action="select"]:before,
.list-albums-images .item-control .toggle-button[data-action="select"]:after {
  display: none;
}
.list-albums-images  .item-control .toggle-button.active .checkbox + span {
  color: #212121;
}
.no-touch .list-albums-images  .item-control .toggle-button.active:hover .checkbox + span {
  color: #fff;
}
.item-control .toggle-button[data-action="select"].active::after {
  background-color: #276fdb;
  border: 1px solid #276fdb;
}
.item-control .toggle-button:last-child {
  margin: 0;
}
.item-control .toggle-button.disabled {
  pointer-events: none;
  color: rgba(33, 33, 33, 0.5);
}

.list-comments .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 12px;
  line-height: 1.2;
  margin: 0 0 2.7rem;
  color: #212121;
}
.list-comments .image {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #e0dfdf;
  overflow: hidden;
  margin: 0 10px 0 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.list-comments .image img {
  width: 100%;
  height: 100%;
}
.list-comments .image .no-thumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0;
  line-height: 0;
  background-size: 4rem;
}
.list-comments .comment-options {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.list-comments .comment-options a {
  min-width: 42px;
  height: 30px;
  border-radius: 15px;
  background-color: #e0dfdf;
  margin: 0 3px 0 0;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.list-comments .comment-options a:last-child {
  margin: 0;
}
.comment-edit {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
}
.no-touch .list-comments .comment-options .comment-edit:hover {
  color: #fff;
  background-color: #276fdb;
}
.list-comments .comment-options a:before {
  font-size: 14px;
  line-height: 30px;
}
.list-comments .comment-options a.comment-dislike.disabled,
.list-comments .comment-options a.comment-like.disabled {
  pointer-events: none;
}
.list-comments .comment-options a.comment-dislike,
.list-comments .comment-options a.comment-like {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0;
  line-height: 0;
  text-align: center;
}
.list-comments .comment-options a.comment-dislike:before {
  content: "\e905";
}
.list-comments .comment-options a.comment-like:before {
  content: "\e906";
}

.no-touch .list-comments .comment-options a.comment-like:hover {
  color: #276fdb;
}
.no-touch .list-comments .comment-options a.comment-dislike:hover {
  color: #f30702;
}
.list-comments .comment-options span {
  border-radius: 15px;
  background-color: #276fdb;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 8px 15px;
  margin: 0 3px 0 0;
}
.list-comments .text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.list-comments .comment-info {
  display: block;
  color: #bcbcbc;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}
.comment-date.icon.icon-calendar:before {
  margin: 0 2px 0 0;
  font-size: 15px;
}
.list-comments p {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  line-height: 2.4rem;
  font-size: 14px;
  padding: 9px 0 0;
}
.list-comments .username {
  font-size: 14px;
  margin: 0 10px 0 0;
}

.list-subscriptions .item {
  display: inline-block;
  background-color: #ffffff;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  text-align: left;
  margin: 10px 0 0 10px;
  border-radius: 5px;
}
.list-subscriptions .item:after {
  content: "";
  display: block;
  clear: both;
}

.list-messages .item {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 10px 0 0 10px;
  border-top: 1px solid #e0dfdf;
  padding-top: 10px;
}
.list-messages .item:first-child,
.list-messages .item.grouped {
  border-top: none;
  padding-top: 0;
}
.list-messages .item.grouped {
  padding-left: 50px;
}
.list-messages .item.me {
  padding-left: 40%;
}
.list-messages .image {
  float: left;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 0 10px 0 0;
}
.list-messages .grouped .image {
  display: none;
}
.list-messages .image a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.list-messages .image:hover {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.list-messages .image img,
.list-messages .image .no-thumb {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #e0dfdf;
  background-size: 70% 70%;
}
.list-messages .message-text {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
}
.list-messages .item.deleted .message-text {
  color: #6f6f6f;
}
.list-messages .message-text .inline-text {
  display: block;
  padding: 10px 0 0 10px;
}
.list-messages .item.editing .message-text {
  background: #c3c3c3;
}
.list-messages .added {
  display: inline-block;
  position: relative;
  color: #bcbcbc;
  font-size: 12px;
  line-height: 1.2;
  padding: 0 0 0 20px;
  margin: 3px 8px 5px 0;
}
.list-messages .added:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  position: absolute;
  font-size: 15px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.list-messages .grouped .added {
  display: none;
}
.list-messages .grouped.me .added {
  display: inline-block;
}
.list-messages .added.editable {
  cursor: pointer;
}
.no-touch .list-messages .added.editable:hover {
  color: #276fdb;
}
.list-messages .added.editable:before {
  content: "\e91e";
}
.list-messages .item.new .added {
  padding: 0 20px;
}
.list-messages .item.new .added:after {
  display: block;
  position: absolute;
  content: "";
  background-color: #ee0000;
  width: 10px;
  height: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border-radius: 50%;
}
.list-messages .load-more {
  margin-top: 15px;
}
.list-messages .bottom {
  padding: 0 0 5px 10px;
}
.list-messages .bottom .submit {
  font-size: 12px;
  padding: 8px 20px;
  min-width: auto;
}

/* pagination */
.pagination {
  text-align: center;
}
.pagination-holder {
  display: inline-block;
  vertical-align: bottom;
}
.pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination li {
  min-width: 50px;
  margin: 1.5px;
}

.pagination a,
.pagination span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  padding: 12px 15px;
  background-color: #e0dfdf;
  color: #212121;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}

.no-touch .pagination a:hover {
  color: #ffffff;
  background-color: #212121;
}

.pagination .page-current a,
.pagination .page-current span {
  color: #ffffff;
  background-color: #276fdb;
  pointer-events: none;
}
.pagination .prev span,
.pagination .next span,
.pagination .first,
.pagination .last {
  display: none;
}
/* load-more */
.load-more {
  padding: 1.5rem 0 0;
  text-align: center;
}
.load-more a {
  display: inline-block;
  padding: 1.2rem 1.4rem;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #e0dfdf;
  border-radius: 20px;
  min-width: 20rem;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
.load-more a:hover {
  color: #ffffff;
  background: #276fdb;
}
/* content */
.content {
  max-width: 1370px;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.main-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 0 2rem;
}

/* sidebar */
.sidebar {
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  border-radius: 5px;
  background-color: #ffffff;
  width: 250px;
  margin: 75px 15px 0 0;
  padding: 2rem;
}
.main-container {
  width: 100%;
}
.sidebar + .main-container {
  width: calc(100% - 265px);
}
.sidebar + .main-container > .headline:first-child,
.sidebar + .main-container div:first-child .headline {
  margin: 0 0 15px -265px;
  width: auto;
}
.sidebar .headline {
  padding: 0;
  margin: 0 0 2.5rem;
}
.sidebar h2 {
  color: #212121;
  font-size: 1.8rem;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.sidebar h1 a,
.sidebar h2 a {
  text-decoration: none;
}
.sidebar .icon:before {
  font-size: 14px;
  margin: 0 1rem 0 0;
}
.sidebar .list {
  padding: 0 10px;
}

.sidebar .comments,
.sidebar .list,
.sidebar .filter {
  margin: 0 0 3rem;
  width: 100%;
}
/* .sidebar div:last-of-type {
	margin: 0;
} */
.sidebar .comments li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0 17px;
  border-bottom: 1px solid #e0dfdf;
  margin: 0 0 2rem;
}
.sidebar .list a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #212121;
  font-size: 14px;
  position: relative;
  padding: 0 0 5px;
  margin: 0 0 10px;
  cursor: pointer;
}
.sidebar .list a::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 3px;
  background-color: #276fdb;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}
.no-touch .sidebar .list a:hover {
  color: #276fdb;
}
.no-touch .sidebar .list a:hover::before {
  width: 100%;
}
.sidebar .list .rating {
  color: #bcbcbc;
  font-size: 14px;
  font-weight: 400;
}
.no-touch .sidebar .list a:hover .rating {
  color: #212121;
}
.sidebar .comments .comment-text {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  line-height: 2.4rem;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.sidebar .comments .comment-text a {
  color: #212121;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.no-touch .sidebar .comments .comment-text a:hover {
  color: #276fdb;
}
.sidebar .comments .comment-info {
  margin: 0 0 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  overflow: hidden;
}
.sidebar .comments .image {
  width: 40px;
  height: 40px;
  background-color: #e0dfdf;
  border-radius: 50%;
  margin: 0 10px 0 0;
  overflow: hidden;
  float: left;
}
.sidebar .comments .image a {
  display: block;
  height: 100%;
}
.sidebar .comments .image img,
.sidebar .comments .image .no-thumb {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
}
.sidebar .comments .image .no-thumb {
  background-size: 23px;
}
.sidebar .comments .username a {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.sidebar .comments .username {
  margin: 0 8px 0 0;
}
.sidebar .comments .username a:hover {
  color: #276fdb;
}
.sidebar .comments .added {
  display: inline-block;
  color: #bcbcbc;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  padding: 0 0 0 20px;
  margin: 3px 8px 0 0;
}
.sidebar .comments .added:before {
  position: absolute;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 15px;
  left: 0;
  top: calc(50% - 1px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  content: "\e902";
}

.sidebar .filter li {
  position: relative;
  background: transparent;
  margin: 0 0 1.5rem;
}
.sidebar .filter li * {
  cursor: pointer;
}
.sidebar .filter li input {
  display: none;
}
.sidebar .filter li label {
  display: block;
  padding: 0 0 0 25px;
  font-size: 14px;
  position: relative;
}
.sidebar .filter li label::before {
  content: "\e904";
  position: absolute;
  left: 0;
  top: -2px;
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e0dfdf;
  background-color: #fff;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  font-size: 11px;
  text-align: center;
  padding: 3px 0 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: background-color 0.3s, border 0.3s, color 0.3s;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.sidebar .filter li input:checked + label::before {
  background-color: #276fdb;
  border: 1px solid #276fdb;
}
.sidebar label.group {
  display: block;
  margin: 0 0 2rem;
}
.sidebar label.group:after {
  content: ":";
}

.sidebar form .selectbox.age {
  width: 90px;
}
.sidebar form .button-group label {
  float: none;
}
.sidebar .alphabet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 10px;
  margin: 0 0 3rem;
}
.sidebar .alphabet span,
.sidebar .alphabet a {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  min-width: calc(20% - 3px);
  margin: 1.5px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  padding: 0.8rem 1.0rem;
  background-color: #e0dfdf;
  color: #212121;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar .alphabet a {
  cursor: pointer;
}
.sidebar .alphabet span {
  color: #a2a2a2;
}

.no-touch .sidebar .alphabet a:hover {
  color: #ffffff;
  background-color: #212121;
}

/* block-channel */
.block-channel {
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  border-radius: 5px;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2rem;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 14px;
  margin: -4px 0 0;
}
.block-channel .img {
  position: relative;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  width: 37rem;
  padding: 0 1rem 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.block-channel .img a {
  display: inline-block;
  width: 100%;
}
.block-channel .img img {
  display: block;
  width: 100%;
}
.block-channel .img .no-thumb {
  display: block;
  width: 100%;
  height: 20rem;
  text-align: center;
  line-height: 200px;
  background-color: #e0dfdf;
  font-size: 0;
  line-height: 0;
  background-size: 6rem;
}
.block-channel .img .channel-user {
  position: absolute;
  right: 15px;
  bottom: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  border-radius: 12px;
  padding: 5px 10px;
  opacity: 0.8;
  background-color: #000;
  width: auto;
}
.block-channel .img .channel-user:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e913";
  margin: 0 5px 0 0;
}

.no-touch .block-channel .img .channel-user:hover {
  background-color: #276fdb;
}

.block-channel .channel-info {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 360px;
  flex: 1 1 360px;
  padding: 0 2rem 0 0;
}
.block-channel .channel-list {
  margin: 0 0 2rem;
}
.block-channel .channel-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 1.5rem;
}
.block-channel .channel-list li:last-child {
  margin: 0;
}
.block-channel .channel-list .name {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 110px;
  flex: 0 0 110px;
  color: #bcbcbc;
  font-size: 14px;
  margin: 0 1rem 0 0;
}
.block-channel .channel-list .rating {
  padding: 3px 0 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}
.block-channel .channel-list .rating span {
  width: calc(10% - 2px);
  height: 6px;
  background-color: #e0dfdf;
  margin: 0 1px 0 0;
  border-radius: 3px;
}
.block-channel .channel-list .rating .full {
  background-color: #276fdb;
}
.block-channel .desc {
  line-height: 20px;
}

.block-channel .channel-rating {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 170px;
  flex: 1 1 170px;
}
.block-channel .channel-rating strong {
  border-radius: 2rem;
  background-color: #e0dfdf;
  color: #212121;
  font-size: 7.2rem;
  font-weight: 700;
  width: 100%;
  padding: 4rem 1rem;
  display: block;
  text-align: center;
  margin: 0 0 1rem;
}
.block-channel .channel-rating a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 20px;
  background-color: #276fdb;
  width: 100%;
  text-align: center;
  display: block;
  padding: 12px 5px;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
.block-channel .channel-rating a.disabled {
  color: #212121;
  background-color: #e0dfdf;
}

.no-touch .block-channel .channel-rating a:hover {
  color: #212121;
  background-color: #e0dfdf;
}
.no-touch .block-channel .channel-rating a.disabled:hover {
  color: #ffffff;
  background-color: #276fdb;
}

/* block-model */
.block-model {
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  padding: 2rem;
  margin: -4px 0 0;
}
.block-model:after,
.block-model:before {
  content: "";
  display: table;
  width: 100%;
  overflow: hidden;
  clear: both;
}
.block-model .img {
  float: left;
  display: block;
  margin: 0 1rem 0 0;
  width: 28rem;
  height: 20rem;
  position: relative;
}
.block-model .img a {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.block-model .img span,
.block-model .img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
}
.block-model .img a img {
  position: inherit;
  left: inherit;
  right: inherit;
  top: inherit;
  bottom: inherit;
  display: block;
  width: 100%;
}
.totals-holder {
  position: absolute;
  z-index: 3;
  bottom: 5px;
  left: 5px;
  color: #fff;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  border-radius: 12px;
  opacity: 0.8;
  padding: 5px 10px;
}
.totals-holder div {
  margin: 0 1rem 0 0;
}
.totals-holder div:last-child {
  margin: 0;
}
.totals-holder .icon::before {
  margin: 0 5px 0 0;
}
.block-model .img .no-thumb {
  background-color: #e0dfdf;
  font-size: 0;
  line-height: 0;
  background-size: 6rem;
  width: 100%;
  padding-bottom: 71.42%;
  display: block;
  position: inherit;
}
.block-model .model-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.block-model .model-list li {
  width: 33.33%;
  color: #bcbcbc;
  margin: 0 0 1.5rem;
  padding: 0 1rem 0 0;
}

.block-model .model-list li span {
  color: #212121;
}
.block-model .desc {
  line-height: 20px;
}
.block-model .website {
  line-height: 20px;
  clear: both;
  padding: 1.5rem 0 0;
  color: #bcbcbc;
}

.block-model .website a {
  color: #276fdb;
}

.no-touch .block-model .website a:hover {
  color: #212121;
}

/* video / album common */

.sponsor {
  width: 100%;
  overflow: hidden;
  margin: 10px 0 0;
}
.sponsor img {
  width: 100%;
  height: auto;
}
.info-holder {
  width: 100%;
}
.info-holder:after {
  content: "";
  display: block;
  clear: both;
}
.info-buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 10px 10px;
  border-bottom: 1px solid #e0dfdf;
  margin: 0 0 2rem;
}

/* block-video */
.block-video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 3rem;
}

.block-video .video-holder {
  margin-right: 1.7rem;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 1px;
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
}
.block-video .player {
  margin: 0 0 2rem;
}
.block-video .player .embed-wrap {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}
.block-video .player .player-wrap {
  width: 100%;
  height: auto;
  position: relative;
}
.block-video .player .player-wrap .poster {
  width: 100%;
  height: auto;
}
.block-video .player .embed-wrap iframe,
.block-video .player .player-wrap #kt_player,
.block-video .player .player-wrap #kt_player_internal {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.block-video .player .player-wrap #kt_player.is-fullscreen {
  position: fixed !important;
}
.block-video .player .loading {
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  color: #444444;
  padding: 50px 20px;
}
.block-video .no-player {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #000;
}

.block-video .no-player img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  object-fit: contain;
}
.block-video .no-player .message {
  position: absolute;
  text-align: center;
  width: 95%;
  left: 50%;
  top: 50%;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.6rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.block-video .no-player .message em {
  font-weight: bold;
}
.block-video .no-player .message a {
  color: #276fdb;
  text-decoration: underline;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.no-touch .block-video .no-player .message a:hover {
  color: #fff;
}

.block-video .no-player .message form {
  display: block;
  padding: 20px 0 0 0;
}
.block-video .no-player .btn-play {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../player/skin/img/play_white.png") 50% 50% no-repeat;
}
.block-video .playlist {
  padding: 2rem;
  border-bottom: 1px solid #e0dfdf;
  margin: 0 0 10px;
}
.block-video .playlist-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 0 -10px;
}
.block-video .playlist .item {
  position: relative;
  cursor: pointer;
  width: calc(25% - 10px);
  margin: 0 0 10px 10px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
}
.block-video .playlist .item.selected {
  pointer-events: none;
}
.no-touch .block-video .playlist .item.selected img {
  border: 4px solid #276fdb;
}
.block-video .playlist .item .title {
  height: 36px;
  margin: 10px;
  padding: 0;
}
.block-video .playlist .item:hover .title {
  color: #276fdb;
}
.block-video .playlist .item .img {
  position: relative;
  padding-bottom: 74.51%;
}
.block-video .playlist .pagination .prev,
.block-video .playlist .pagination .next {
  display: block;
}
.block-video .playlist .pagination .first,
.block-video .playlist .pagination .last,
.block-video .playlist .pagination .page,
.block-video .playlist .pagination .jump {
  display: none;
}
.block-video .sponsor {
  margin: 0 auto 2rem;
  max-width: 730px;
}
.sponsor a {
  display: block;
}
.table {
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  width: 320px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 0;
  flex-shrink: 0;
  flex-grow: 0;
}
.table .opt {
  margin: 0 0 10px;
  width: 300px;
  height: 250px;
  display: inline-block;
}
.table .opt img,
.table .opt a {
  display: block;
  width: 100%;
  height: 100%;
}
.table .opt:last-child {
  margin: 0;
}
/* block-album */
.block-album {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}
.block-album .album-holder {
  margin: 0 1.7rem 3rem 0;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 1px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
}
.block-album .images {
  position: relative;
  min-height: 20rem;
  padding: 0 3rem 3rem;
  margin: 0 0 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 1px solid #e0dfdf;
}

.block-album .images span.item,
.block-album .images a.item {
  display: inline-block;
  /* width: calc(25% - 3rem);
  padding-bottom: 28.6%; */
  margin: 3rem 1.5rem 0;
  position: relative;
}
.block-album .images .item img {
  width: 100%;
  display: block;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2; */
}
/* .block-album .images span.item {
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity=50);
} */
.block-album .images a.item:hover {
  border-color: #e62117;
}
.block-album .images .message {
  position: absolute;
  text-align: center;
  padding: 40px 20px 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: 500;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
}
.block-album .images .message em {
  font-weight: bold;
}
.block-album .images .message a {
  color: #276fdb;
  margin: 0 5px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.no-touch .block-album .images .message a:hover {
  color: #fff;
}

.block-album .images .message form {
  display: block;
  padding: 20px 0 0 0;
}
.block-album .sponsor {
  margin: 0 auto 2rem;
  max-width: 730px;
}
/* video / album rating */
.rating-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 2rem;
}
.rate-dislike,
.rate-like {
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  width: 21px;
  height: 20px;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.rate-like {
  margin: 0 5px 0 0;
}
.rate-dislike {
  margin: 0 10px 0 0;
}

.rate-dislike:before,
.rate-like:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 20px;
  color: #bcbcbc;
  content: "\e90b";
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.rate-dislike:before {
  -webkit-transform: translate(-50%, -50%) rotate(180deg);

  transform: translate(-50%, -50%) rotate(180deg);
}
.rate-like.disabled,
.rate-dislike.voted,
.rate-dislike.disabled,
.rate-like.voted {
  pointer-events: none;
}
.rate-like.voted::before {
  color: #276fdb;
}
.rate-dislike.voted:before {
  color: #f30702;
}

.no-touch .rate-dislike:hover::before {
  color: #f30702;
}
.no-touch .rate-like:hover::before {
  color: #276fdb;
}

.rating-container .rating {
  min-width: 100px;
  text-align: center;
}
.rating-container .voters {
  display: block;
  color: #212121;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.rating-container .scale-holder {
  height: 4px;
  border-radius: 2px;
  background-color: #bcbcbc;
  display: block;
  position: relative;
  overflow: hidden;
}
.rating-container .scale-holder .scale {
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  background-color: #276fdb;
  border-radius: 2px;
}
.rating-container .scale-holder.negative .scale {
  background-color: #f30702;
}
/* video / album add to favourites */
.btn-favourites {
  display: block;
  position: relative;
  width: 7rem;
  height: 4rem;
  border-radius: 2rem;
  background-color: #e0dfdf;
  padding: 2px 0;
  -webkit-transition: background-color 0.3s, border-radius 0.3s;
  transition: background-color 0.3s, border-radius 0.3s;
}
.btn-favourites .ico-favourite {
  width: 2.2rem;
  height: 2rem;
  display: block;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  font-size: 1.9rem;
}
.btn-favourites .ico-favourite:before {
  content: "\e90c";
}
.btn-favourites:hover {
  background-color: #276fdb;
  border-radius: 20px 20px 0 0;
}
.btn-favourites .drop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  color: #212121;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.btn-favourites:hover .drop {
  color: #ffffff;
}
.btn-favourites .drop:after {
  content: "\e901";
  display: block;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  right: 1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  font-size: 6px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.btn-favourites:hover .drop:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.btn-favourites ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  overflow: hidden;
  background-color: #e0dfdf;
  border-radius: 0 20px 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s,
    -webkit-transform 0.3s;
}
.btn-favourites:hover ul {
  display: block;
}

.btn-favourites:hover ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.btn-favourites ul li > a,
.btn-favourites ul li > span {
  white-space: nowrap;
  display: block;
  font-weight: bold;
  background-color: transparent;
  color: #212121;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.btn-favourites ul li > a {
  padding: 1.2rem;
}
.btn-favourites ul li > a:hover,
.btn-favourites ul li > span:hover {
  background-color: #276fdb;
  color: #fff;
}
.btn-favourites ul li > a:hover,
.btn-favourites ul li > span:hover a {
  color: #fff;
}
.btn-favourites ul li > span:hover::before {
  color: #fff;
}
.btn-favourites ul li span {
  position: relative;
  padding: 1.2rem 3.8rem;
}
.btn-favourites ul li span:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.5rem;
  color: #276fdb;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  content: "\e904";
}
.btn-favourites ul li span .delete {
  position: absolute;
  background-color: #212121;
  text-decoration: none;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  right: 0.8rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
  line-height: 0;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  padding: 0.6rem 0 0;
  -webkit-transition: color 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: color 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: color 0.3s, transform 0.3s, background-color 0.3s;
  transition: color 0.3s, transform 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: color 0.3s, transform 0.3s, background-color 0.3s,
    -webkit-transform 0.3s;
}
.btn-favourites ul li span .delete:before {
  content: "\e922";
  font-size: 0.8rem;
}

.btn-favourites ul li .delete:hover {
  background-color: #e0dfdf;
}
.btn-favourites ul li .delete:hover::before {
  color: #212121;
}
.btn-favourites ul li span a {
  padding: 0;
  color: #212121;
  text-decoration: underline;
}
.no-touch .btn-favourites ul li span a:hover {
  text-decoration: none;
}
/* video / album tabs */
.tabs-menu {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 2px 0;
}
.tabs-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 0;
  flex: 1 0;
}
.tabs-menu li {
  margin-right: 3px;
  padding: 2px 0;
}
.tabs-menu li:last-child {
  margin: 0;
}
.toggle-button.icon,
.tabs-menu .toggle-button.icon {
  position: relative;
  padding: 1.1rem 1.5rem 1.1rem 4rem;
}
.toggle-button.icon:before,
.tabs-menu .toggle-button.icon:before {
  position: absolute;
  left: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 2rem;
  color: #212121;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.tabs-menu .toggle-button {
  background-color: #e0dfdf;
  padding: 1.1rem 1.1rem;
  white-space: nowrap;
  font-size: 12px;
  border: 0;
}
.tabs-menu .toggle-button.active {
  background-color: #276fdb;
  pointer-events: none;
}
.tabs-menu .toggle-button.active.icon::before {
  color: #fff;
}

.no-touch .toggle-button.icon:hover::before,
.no-touch .tabs-menu .toggle-button.icon:hover::before {
  color: #fff;
}

.no-touch .tabs-menu .toggle-button:hover {
  background-color: #276fdb;
  color: #fff;
}
.tab-content {
  background: transparent;
  padding: 0 2rem 2rem;
}
/* video / album details */
.block-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.block-details .info {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding-right: 1rem;
}
.block-details .item {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  margin: 0 30px 2rem 0;
  display: inline-block;
  /* display: flex; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
}
.block-details .item:first-child {
  display: block;
  margin: 0 0 2rem;
}
.block-details .item:last-child {
  margin: 0;
}
.block-details .item img {
  margin: 1px;
}
.block-details .item a {
  display: inline-block;
  color: #212121;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  border-radius: 15px;
  padding: 8px 10px;
  background-color: #e0dfdf;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  margin: 2px 0 2px 3px;
}
.block-details .item a:first-child {
  margin-left: 10px;
}

.no-touch .block-details .item a:hover {
  color: #fff;
  background-color: #276fdb;
}

.block-details .item span {
  color: #bcbcbc;
  font-weight: 400;
  margin: 0 2.5rem 0 0;
  position: relative;
}
.block-details .item span.icon {
  padding-left: 20px;
}
.block-details .item span.icon-album,
.block-details .item span.icon-calendar {
  padding-left: 22px;
}
.block-details .item span.icon-eye {
  padding-left: 27px;
}
.block-details .item span.icon:before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 15px;
}
.block-details .item span.added-by {
  display: none;
}
.block-details .item span.added-by:after {
  display: none;
}
.block-details .item span em {
  color: #212121;
  font-weight: 400;
  margin-left: 3px;
  padding: 0;
}
.block-details .item em {
  color: #212121;
  padding: 0 0 0 10px;
}
.block-details .block-user {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.block-details .block-user .username {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-weight: 500;
  font-size: 12px;
}
.block-details .block-user .username a {
  border-radius: 15px;
  background-color: #276fdb;
  color: #ffffff;
  padding: 8px 15px;
  display: inline-block;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
}

.no-touch .block-details .block-user .username a:hover {
  background-color: #e0dfdf;
  color: #212121;
}

.block-details .block-user .avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e0dfdf;
  width: 6rem;
  height: 6rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.no-touch .block-details .block-user a.avatar:hover {
  opacity: 0.7;
}

.block-details .block-user .avatar img,
.block-details .block-user .avatar .no-thumb {
  display: block;
  height: 4rem;
  width: 4rem;
  background-size: contain;
  font-size: 0;
  line-height: 0;
}
.block-details .block-user .avatar img {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
}

/* video / album flagging */
.block-flagging {
  width: 100%;
  overflow: hidden;
}
.block-flagging .generic-error {
  width: 100%;
  text-align: center;
  max-width: 100%;
}
.block-flagging form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.block-flagging .block-radios {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 220px;
  flex: 0 0 220px;
  padding-right: 1rem;
}
.block-flagging .block-textarea {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}
.block-flagging .button-group .row {
  margin: 0 0 10px;
  padding: 6px 0 0;
}
.block-flagging .submit {
  margin: 11px 0 0;
}
/* video screenshots */
.block-screenshots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px 0 0 -5px;
}

.block-screenshots span.item,
.block-screenshots a.item {
  display: inline-block;
  width: calc(20% - 5px);
  position: relative;
  padding-bottom: 10.84%;
  margin: 5px 0 0 5px;
}
.block-screenshots span.item:before {
  content: "\e912";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  border-radius: 12px;
  background-color: #276fdb;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 1px 8px 3px 8px;
}
.block-screenshots a.item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background-color: transparent;
  border: 4px solid #276fdb;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.block-screenshots a.item:hover::before {
  opacity: 1;
  visibility: visible;
}
.block-screenshots span.item img,
.block-screenshots a.item img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
/* video / album sharing */
.block-share {
  width: 100%;
  overflow: hidden;
}
.block-share .row {
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.block-share label {
  width: 100%;
  margin: 0 0 10px;
}
.block-share .middle {
  width: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 58%;
  flex: 0 1 58%;
}
.block-share .block-size {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.block-share .block-size a {
  color: #ffffff;
  padding: 13px 20px;
  background-color: #e0dfdf;
  border-radius: 20px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  color: #212121;
  display: inline-block;
  margin: 3px 3px 3px 0;
}

.no-touch .block-share .block-size a:hover {
  background-color: #276fdb;
  color: #fff;
}

.block-share .custom-size {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 0 0 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 3px 0;
}
.block-share .custom-size label {
  margin: 0 1rem 0 0;
  width: auto;
  white-space: nowrap;
}
.block-share .custom-size .textfield {
  width: 60px;
  margin: 0 1rem 0 0;
  text-align: center;
  padding: 12px 5px;
}
.block-share .custom-size .textfield:last-child {
  margin: 0;
}
/* video / album comments */
.block-comments {
  width: 100%;
}
.block-comments form {
  clear: both;
  margin: 0 0 2rem;
}
.block-comments form > .field-label {
  font-size: 18px;
}
.block-comments .toggle-button {
  float: right;
  display: block;
  background-color: #e0dfdf;
  border: none;
  min-width: 20rem;
  padding: 10px 10px;
  text-align: center;
}
.block-comments .toggle-button.active {
  background-color: #276fdb;
  color: #ffffff;
}

.no-touch .block-comments .toggle-button:hover {
  background-color: #276fdb;
  color: #ffffff;
}
.no-touch .block-comments .toggle-button.active:hover {
  background-color: #e0dfdf;
  color: #212121;
}

.hint {
  color: #9f9f9f;
  font-size: 12px;
  font-weight: 400;
}
.block-comments .block-new-comment {
  padding: 2.5rem 0 0;
  display: none;
}
.block-comments form .row {
  max-width: 100%;
}
.block-comments .bottom {
  max-width: 100%;
  width: 100%;
}
.block-comments .list-comments {
  border-top: 1px solid #e0dfdf;
  padding: 2rem 0 0;
}
/* related videos, albums */
.list-sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 2rem;
}
.list-sort li {
  margin-right: 2.6rem;
  font-size: 2.4rem;
  padding: 2px 0;
}
.list-sort li:last-child {
  margin: 0;
}
.related-videos {
  width: 100%;
}
.related-videos,
.related-albums {
  width: 100%;
}
.related-videos .list-sort a,
.related-albums .list-sort a,
.related-videos .list-sort span,
.related-albums .list-sort span {
  display: block;
  padding: 0 6px 0.9rem;
  position: relative;
}
.related-videos .list-sort a:before,
.related-albums .list-sort a:before,
.related-videos .list-sort span:before,
.related-albums .list-sort span:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: #276fdb;
  width: 0%;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  z-index: 2;
}

.related-videos .list-sort span:before,
.related-albums .list-sort span:before {
  width: 100%;
}
.related-videos .list-sort span,
.related-albums .list-sort span,
.related-videos .list-sort a:hover,
.related-albums .list-sort a:hover {
  color: #276fdb;
}
.related-videos .list-sort a:hover::before,
.related-albums .list-sort a:hover::before {
  width: 100%;
}
/* playlist subscribe */
.btn-subscribe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.btn-subscribe .toggle-button {
  padding: 7px 15px;
  color: #fff;
  background-color: #276fdb;
  white-space: nowrap;
  font-size: 12px;
  border: 0;
  border-radius: 15px 0 0 15px;
}
.no-touch .btn-subscribe .toggle-button:hover {
  background-color: #c6c6c6;
  color: #212121;
}
.btn-subscribe .button-info {
  background-color: #e0dfdf;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  border-radius: 0 15px 15px 0;
  font-size: 12px;
  padding: 7px 15px 7px 30px;
  position: relative;
}
.btn-subscribe .button-info:before {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  content: "\e913";
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* member-menu */
.member-menu {
  width: 25rem;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  border-radius: 5px;
  background-color: #ffffff;
  margin: 3rem 0 0;
  padding: 2rem;
  position: relative;
}
.member-menu .headline {
  padding: 0;
  margin: 0 0 2rem;
}

.member-menu + .main-container-user {
  width: calc(100% - 25rem);
  padding: 0 10px 0 0;
}
.member-menu .block-main {
  width: 100%;
  margin: 0 0 2rem;
}
.member-menu .block-main .avatar {
  margin: 0 auto 4.4rem;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: #e0dfdf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  overflow: hidden;
}
.member-menu .block-main .avatar img {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;
  line-height: 0;
  border-radius: 50%;
}

.member-menu .block-main .avatar .no-thumb {
  width: 6rem;
  height: 6rem;
  text-align: center;
  font-size: 0;
  line-height: 0;
  background-size: 6rem 6rem;
}
.member-menu .block-additional {
  width: 100%;
  border-top: 1px solid #e0dfdf;
  padding: 3rem 0 0;
}
.user-menu span,
.user-menu a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  padding: 0 0 10px;
  font-weight: 500;
}
.user-menu span {
  color: #276fdb;
}

.user-menu span:before,
.user-menu a:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: #276fdb;
  z-index: 1;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}
.user-menu span:before {
  width: 100%;
}
.user-menu a:before {
  width: 0%;
}

.no-touch .user-menu a:hover {
  color: #276fdb;
}
.no-touch .user-menu a:hover::before {
  width: 100%;
}
.member-menu .user-menu span em,
.no-touch .user-menu a:hover em {
  color: #212121;
}

.user-menu {
  display: block;
}
.user-menu li {
  display: block;
  margin: 0 0 10px;
}
.user-menu li:last-child {
  display: block;
  margin: 0;
}
.member-menu .user-menu em {
  color: #bcbcbc;
  font-size: 14px;
  font-weight: 400;
}
.member-menu .user-menu em.small {
  font-size: 9px;
}
/* profile */
.member-menu + .main-container-user .item {
  width: calc((100% - 32px) / 3);
}
.member-menu + .main-container-user .list-albums .item,
.member-menu + .main-container-user .list-channels .item {
  width: calc((100% - 40px) / 4);
}
.member-menu + .main-container-user .list-members .item {
  width: calc(20% - 10px);
}
.member-menu + .main-container-user .list-messages .item {
	width: calc(100% - 10px);
}
.block-profile {
  background-color: #ffffff;
  -webkit-box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  box-shadow: -1px 1px 5px rgba(207, 207, 207, 0.65);
  border-radius: 5px;
  padding: 2rem;
  overflow: hidden;
}
.profile-list {
  width: 100%;
  overflow: hidden;
}
.profile-list .margin-fix {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 1.2;
  color: #bcbcbc;
}
.profile-list .about-me {
  width: 100%;
}
.profile-list .about-me em {
  display: block;
  padding: 8px 0 0;
  line-height: 2rem;
}
.profile-list .column {
  width: 33%;
  padding-right: 10px;
}

.member-menu + .main-container-user .profile-list .item {
  width: 100%;
}
.profile-list .column .item {
  margin: 0 0 10px;
  overflow: hidden;
}
.profile-list .item em {
  color: #212121;
}

/* video / album edit */
.form-upload {
  position: relative;
}
.form-upload.uploading {
  height: 80px;
  cursor: wait;
}
.form-upload.uploading * {
  opacity: 0;
  visibility: hidden;
}
.form-upload.uploading .progressbar,
.form-upload.uploading .progressbar * {
  opacity: 1;
  visibility: visible;
}
.form-upload.uploading-finished {
  cursor: default;
}
.form-upload p {
  display: block;
  margin: 1rem 0 0;
}
.form-upload p.preview {
  display: block;
  max-width: 320px;
  overflow: hidden;
  background: #c8c8c8;
  position: relative;
  margin-bottom: 2rem;
}
.form-upload p.preview em {
  position: absolute;
  bottom: 5px;
  background: #ffffff;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.form-upload p.preview em.left {
  left: 5px;
  margin-right: 50px;
}
.form-upload p.preview em.right {
  right: 5px;
}
.form-upload p.preview em.negative {
  color: #e62117;
}
.form-upload p.preview img {
  display: block;
  max-width: 320px;
  width: 100%;
  height: auto;
}
.form-upload p.preview.disabled img {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
/* popups */
/* fancybox */
.fancybox-opened .fancybox-skin {
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: -7px 10px 21px rgba(0, 0, 0, 0.65) !important;
  box-shadow: -7px 10px 21px rgba(0, 0, 0, 0.65) !important;
  padding: 0 !important;
  overflow: hidden;
}
.fancybox-inner {
  width: 100%;
}
.fancybox-close {
  top: 1.9rem !important;
  right: 1rem !important;
}
.fancybox-lock .fancybox-overlay {
  z-index: 9999;
}
.fancybox-error {
  padding: 3rem 15px 15px !important;
}
.popup-title {
  display: block;
  color: #212121;
  font-size: 14px;
  padding: 2.5rem 5rem 2.5rem 3rem;
  font-size: 2.4rem;
  font-weight: 500;
}
.popup-holder {
  padding: 3rem;
  min-width: 42rem;
  background-color: #f9f9f9;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#e7e7e7));
  background-image: linear-gradient(to top, #ffffff 0%, #e7e7e7 100%);
}
.two-sections {
  width: 81rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main-container-user .two-sections {
  width: 100%;
}
.section-one,
.section-two {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
.section-one {
  padding: 0 25px 0 0;
  margin: 0 0 2rem;
}
.section-two {
  padding: 0 0 0 25px;
}
.main-container-user .section-two {
  -ms-flex: 0 1 320px;
  flex: 0 1 320px;
}
/* block UI plugin overlay */

/* posts */
.list-posts .item {
  margin: 15px 0 0 15px;
}

.list-posts .item .title {
  height: auto;
  margin: 7px 0;
}
.list-posts .item:hover .title {
  text-decoration: underline;
}
.list-posts .item .description {
  color: #1d1d1d;
}
.list-posts .item .wrap .added {
  float: right;
}
.block-post {
  padding: 10px 0;
  background: #ffffff;
}
.block-post:after {
  content: "";
  display: block;
  clear: both;
}
.block-post .post-holder {
  overflow: hidden;
  padding-right: 7px;
}
.block-post .post-content {
  margin: 0 0 10px;
}
.block-post .sponsor {
  width: auto;
  margin: 10px 0;
}
.block-post .table {
  float: right;
  margin-left: 10px;
  padding: 10px;
  background: #d1d1d1;
}
.block-post .table .opt {
  margin: 0 0 10px;
  width: 315px;
  height: 300px;
}
.block-post .table .opt:last-child {
  margin: 0;
}
/* related videos in player */
.player-related-videos {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 5px 10px 30px 10px;
  background: #000000;
  overflow: hidden;
}
.player-related-videos .player-related-videos-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
}
.player-related-videos .player-related-videos-item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
}
.player-related-videos .player-related-videos-item .title {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 52px;
  overflow: hidden;
  text-align: left;
  padding: 5px;
  color: #ffffff;
  background: linear-gradient(
    to bottom,
    rgba(12, 12, 12, 0.8) 0px,
    transparent 50px
  );
}
.player-related-videos .player-related-videos-item .duration {
  display: block;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #ffffff;
  background: rgba(12, 12, 12, 0.8);
  padding: 2px 5px;
}
.no-touch .player-related-videos .player-related-videos-item .title,
.no-touch .player-related-videos .player-related-videos-item .duration {
  display: none;
}
.no-touch .player-related-videos .player-related-videos-item:hover .title,
.no-touch .player-related-videos .player-related-videos-item:hover .duration {
  display: block;
}

/* responsive */
@media screen and (max-width: 1280px) {
  .place {
    padding: 4vw 0;
  }
  .sidebar + .main-container .list-albums .place {
    padding: 7vw 0;
  }
  .bottom-adv {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media screen and (max-width: 1255px) {
  .place {
    padding: 2.8vw 0;
  }
  .sidebar + .main-container .place {
    padding: 1.4vw 0;
  }
  .top-links .center-hold {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .network {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    text-align: center;
    margin: 0 0 5px;
  }
  .member-links {
    width: 100%;
  }
  .network ul {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .member-links ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .header {
    padding: 10px;
  }
  .bottom-adv .spot:nth-child(4) {
    display: none;
  }
}
@media screen and (max-width: 1255px) {
  .block-channel,
  .block-model {
    margin: 0;
  }
  .sidebar {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin: 2.5rem 0;
  }
  .sidebar + .main-container .list-albums .place {
    padding: 16vw 0;
  }
  .sidebar + .main-container {
    width: 100%;
  }
  .sidebar + .main-container > .headline:first-child,
  .sidebar + .main-container div:first-child .headline,
  .sidebar + .main-container .headline {
    margin: 0 0 15px 0;
  }
  .sidebar + .main-container .list-playlists .item,
  .sidebar + .main-container .list-videos .item {
    width: calc(25% - 10px);
  }
  .sidebar + .main-container .place ~ .item:nth-of-type(-n + 7) {
    width: calc((100% - 362px) / 3);
  }

  .sidebar .comments,
  .sidebar .list,
  .sidebar .filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 0 2rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .sidebar label.group + ul,
  .sidebar label.group {
    width: 100%;
  }
  .sidebar label.group + ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .sidebar .filter li label {
    margin: 0;
  }
  .sidebar .list {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .sidebar .comments {
    margin: 0 -2rem -1rem 0;
  }
  .sidebar .list a {
    margin: 0;
  }
  .sidebar .headline {
    margin: 0 0 1.5rem;
  }
  .sidebar .list .rating {
    padding: 0 0 0 2rem;
  }
  .sidebar .comments li,
  .sidebar .list li,
  .sidebar .filter li {
    width: calc(33.33% - 2rem);
    margin: 0 0 2rem 0;
  }
  .sidebar .list li {
    padding: 0 10px 0 0;
  }
  .sidebar .alphabet span,
  .sidebar .alphabet a {
    min-width: 3.5rem;
  }
  .sidebar form {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .sidebar form .row {
    width: 33.333%;
    max-width: 100%;
    display: inline-block;
    padding: 0 5px;
  }
  .sidebar .filter form .row:nth-child(5) {
    margin: 0;
  }
  .sidebar .filter form .button-group {
    /* conflict on sidebr comunity */
    margin: 0;
  }
  .sidebar .filter form .button-group label {
    /* conflict on sidebr comunity */
    margin: 0;
  }
  form .button-group .row:first-child {
    width: 100%;
  }
  .info-buttons {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .tabs-menu {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 1rem 0 0;
  }
  .tabs-menu ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .block-album .images {
    padding: 0 1.5rem 1.5rem;
  }
  .block-album .images span.item,
  .block-album .images a.item {
    /* width: calc(25% - 2rem); */
    margin: 1.5rem 1rem 0;
  }
}
@media screen and (max-width: 1152px) {
  html {
    font-size: 58.5%;
  }
  .navigation .primary {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    top: calc(100% + 1px);
    left: 0;
    max-width: 330px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.28);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.28);
    -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s,
      -webkit-transform 0.3s;
    z-index: 1000;
  }
  .navigation .primary ul {
    top: 39px;
  }

  .navigation .primary .highlight.drop {
    padding: 5px 0 0;
    margin: 0;
  }
  .navigation .primary > li:first-child,
  .navigation .primary > li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 0 0 3px;
  }
  #item1 {
    height: 34px;
  }
  .navigation .primary a {
    padding: 9px 4px;
  }
  .navigation .primary .drop > a {
    padding: 9px 38px 9px 54px;
  }
  .navigation .button {
    display: block;
  }
  .secondary {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    z-index: 5;
  }
  .navigation .secondary a {
    padding: 11px 6px;
  }
  .navigation.open .primary {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .sidebar + .main-container .place {
    padding: 0.7vw 0;
  }
  .sidebar + .main-container .list-albums .place {
    padding: 14vw 0;
  }
  .block-details {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .block-details .block-user {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 1rem;
  }
  .block-details .info {
    padding: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .block-video .sponsor {
    margin: 0 auto 1rem;
  }
  .info-buttons {
    margin: 0 0 1rem;
  }
}
@media screen and (max-width: 1140px) {
  .place {
    padding: 1vw 0;
  }
  .sidebar .comments li {
    width: calc(50% - 1rem);
    margin: 0 0 2rem;
  }

  .sidebar {
    margin: 1.5rem 0 2rem;
    padding: 1rem;
  }
  .sidebar + .main-container .list-albums .place {
    padding: 13vw 0;
  }
  .block-screenshots {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .block-screenshots span.item,
  .block-screenshots a.item {
    width: calc(33.33% - 5px);
    padding-bottom: 18.34%;
  }
}
@media screen and (max-width: 1050px) {
  .place {
    display: none;
  }
  .sidebar + .main-container .list-albums .place ~ .item:nth-of-type(-n + 7),
  .place ~ .item:nth-of-type(-n + 7),
  .sidebar + .main-container .place ~ .item:nth-of-type(-n + 7) {
    width: calc(25% - 10px);
  }
  .member-menu {
    position: inherit;
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .member-menu + .main-container-user .item {
    width: calc((100% - 40px) / 4);
  }
  .member-menu + .main-container-user .list-videos-screenshots .item,
  .member-menu + .main-container-user .list-albums-images .item {
    width: calc((100% - 32px) / 3);
  }
  .member-menu + .main-container-user {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    padding: 0;
  }
  .member-menu .block-main .avatar {
    /* width: 15rem;
    height: 15rem; */
    margin: 0 4rem 0 0;
  }
  .member-menu .headline {
    width: 100%;
  }

  /* .member-menu .block-main .avatar .no-thumb {
    width: 10rem;
    height: 10rem;
    background-size: 10rem 10rem;
  } */
  .member-menu .block-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 400px;
    flex: 0 1 400px;
    width: auto;
    margin: 0 2rem 0 0;
  }
  .block-main .user-menu {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
  }
  .member-menu .block-additional {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 250px;
    flex: 0 1 250px;
    width: auto;
    padding: 0 0 0 2rem;
    border-top: 0;
    border-left: 1px solid #e0dfdf;
  }
  /* .block-album .images span.item,
  .block-album .images a.item {
    width: calc(33.333% - 2rem);
    padding-bottom: 40.1%;
  } */
  .block-video .playlist .item {
    width: calc(33.33% - 10px);
  }
}
@media screen and (max-width: 1040px) {
  .place {
    padding: 2vw 0;
  }
  .list-channels .item,
  .list-models .item,
  .list-sponsors .item,
  .list-categories .item {
    width: calc(25% - 10px);
  }
  .search form {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .search {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 0 0 0 40px;
  }
  .logo {
    margin: 0 10px 0 0;
  }
  .search .search-text input {
    padding: 10px 60px 10px 20px;
  }
  .block-share .middle {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 52.5%;
  }
  form [data-name="code"],
  form .row {
    margin: 0 0 1.5rem;
  }
  .block-channel {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .block-channel .channel-info {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 1.5rem 0 0;
  }
  .block-channel .channel-rating {
    -webkit-box-flex: 11;
    -ms-flex: 11 1;
    flex: 11 1;
  }
  .list-tags .margin-fix {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .list-tags .margin-fix .item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }
}
@media screen and (max-width: 980px) {
  .bottom-adv .spot:nth-child(3) {
    display: none;
  }
  .list-members .item {
    width: calc(25% - 10px);
  }
}
@media screen and (max-width: 940px) {
  .block-model .model-list li {
    width: 50%;
  }
  .block-video .playlist .item {
    width: calc(50% - 10px);
  }
  .pagination .first,
  .pagination .last,
  .pagination .page,
  .pagination .jump {
    display: none;
  }
  .block-album .images .message,
  .block-video .no-player .message {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}
@media screen and (max-width: 860px) {
  .sidebar + .main-container .list-albums .place ~ .item:nth-of-type(-n + 7),
  .list-albums .item,
  .member-menu + .main-container-user .list-albums .item,
  .member-menu + .main-container-user .list-channels .item,
  .member-menu + .main-container-user .list-members .item,
  .sidebar + .main-container .list-videos .item,
  .sidebar + .main-container .place ~ .item:nth-of-type(-n + 7),
  .place ~ .item:nth-of-type(-n + 7),
  .list-playlists .item,
  .list-videos .item {
    width: calc(33.33% - 10px);
  }
  .member-menu + .main-container-user .item {
    width: calc((100% - 32px) / 3);
  }
  .block-album .table,
  .block-video .table,
  .block-post .table,
  .list-videos .place,
  .list-albums .place,
  .list-playlists .place {
    display: none;
  }

  .block-album .album-holder,
  .block-video .video-holder {
    margin-right: 0;
  }
  .headline {
    margin: 0 0 10px;
  }
  .two-sections {
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .section-one {
    padding: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .main-container-user .section-two,
  .section-two {
    padding: 0 0 0 10px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 0;
    margin: 0 0 10px;
  }
  form .bottom {
    max-width: 100%;
  }
  form .row {
    max-width: 100%;
  }
  .sidebar .list li,
  .sidebar .filter li {
    width: 33.33%;
  }
  .sidebar .comments,
  .sidebar .list,
  .sidebar .filter {
    padding: 0;
  }
  .list-members .item {
    width: calc(33.33% - 10px);
  }
  .member-menu .block-main {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 53%;
    flex: 0 1 53%;
  }
  .member-menu .block-additional {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
  }
  .profile-list .column {
    width: 50%;
  }
  .block-video .playlist .item {
    width: calc(33.33% - 10px);
  }
}
@media screen and (max-width: 785px) {
  .box {
    padding: 1.5rem 0 0;
  }
  .list-channels .item,
  .list-models .item,
  .list-sponsors .item,
  .list-categories .item {
    width: calc(33.33% - 10px);
  }
  .headline {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2rem 0 0;
  }
  .headline h1,
  h2 {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 2px 0;
  }
  .sort {
    margin: 10px 10px 0 0;
  }
  .sort:last-child {
    margin: 10px 0 0;
  }
  .pagination a,
  .pagination span {
    font-size: 13px;
    padding: 8px 12px;
  }
  .pagination li {
    min-width: 43px;
  }
  .tags-cloud .all {
    font-size: 13px;
    padding: 10px 20px;
  }
  .footer .nav a {
    font-size: 13px;
  }
  .sidebar form .row {
    width: 50%;
  }
  .block-details .item span {
    margin: 0 1.5rem 0.5rem 0;
  }
  .block-details .item {
    margin: 0 10px 1rem 0;
  }
  .tab-content {
    padding: 0 1rem 1rem;
  }
  .list-sort {
    margin: 0 0 1rem;
  }
  .list-sort li {
    margin-right: 1rem;
  }

  .block-model .model-list li {
    width: 100%;
    padding: 0;
  }
  .block-video .playlist .item {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 650px) {
  .bottom-adv .spot:nth-child(2) {
    display: none;
  }
  html {
    font-size: 47.5%;
  }
  .list-members .item {
    width: calc(50% - 10px);
  }
  .member-menu .block-main {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 0;
  }
  .user-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .user-menu li {
    width: 100%;
    padding: 0 0.8rem;
  }
  .member-menu .block-additional {
    padding: 10px 0 0;
    border-top: 0;
    border-left: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .list-tags .margin-fix .item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
  }
}
@media screen and (max-width: 640px) {
  .logo {
    margin: 0 0 10px;
  }
  .search {
    padding: 0;
  }
  .header-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sidebar + .main-container .list-albums .place ~ .item:nth-of-type(-n + 7),
  .list-albums .item,
  .member-menu + .main-container-user .list-albums .item,
  .member-menu + .main-container-user .list-channels .item,
  .member-menu + .main-container-user .list-members .item,
  .sidebar + .main-container .list-videos .item,
  .sidebar + .main-container .place ~ .item:nth-of-type(-n + 7),
  .place ~ .item:nth-of-type(-n + 7),
  .list-playlists .item,
  .list-videos .item {
    width: calc(50% - 10px);
  }
  .list-playlists .item,
  .list-videos .item {
    margin: 5px 0 0 5px;
  }
  .margin-fix {
    margin: -5px 0 0 -5px;
  }
  .empty-content {
    padding: 0 0 0 5px;
  }
  .member-menu + .main-container-user .item {
    margin: 5px 0 0 5px;
    width: calc((100% - 10px) / 2);
  }
  .list-channels .item,
  .list-models .item,
  .list-sponsors .item,
  .list-categories .item {
    width: calc(50% - 5px);
    margin: 5px 0 0 5px;
  }
  .sidebar .comments li {
    width: 100%;
    margin: 0 0 2rem;
  }
  .sidebar .list li,
  .sidebar .filter li {
    width: 50%;
  }
  .btn-favourites ul {
    left: inherit;
    right: 0;
    border-radius: 20px 0 20px 20px;
  }
  form [data-name="code"],
  form .row {
    margin: 0 0 1rem;
  }
  .block-video {
    margin: 0 0 1.5rem;
  }
  .list-comments .comment-info {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 0 0 5px;
  }
  .box {
    margin: 0 0 1.5rem;
  }
}
@media screen and (max-width: 560px) {
  .box {
    padding: 1rem 0 0;
  }
  .popup-holder {
    min-width: 380px;
    padding: 10px;
  }
  .popup-title {
    padding: 1.5rem 3.5rem 1.5rem 1.5rem;
  }
  .fancybox-close {
    top: 0.8rem !important;
  }
  form .section-title {
    margin: 0 0 1.5rem;
  }
  .sidebar form .row {
    width: 100%;
  }
  form .button-group .row:first-child {
    margin: 0 0 2rem;
  }
  .block-screenshots span.item,
  .block-screenshots a.item {
    width: calc(50% - 5px);
    padding-bottom: 27.54%;
  }
  .block-new-comment .bottom .submit {
    margin: 0;
  }
  .block-comments form .textfield {
    width: 100%;
  }
  .member-menu .block-main {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
  }
  .member-menu .block-main .avatar {
    margin: 0 0 1rem;
  }
  .block-main .user-menu {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .user-menu li {
    display: inline-block;
    width: auto;
  }
  .member-menu .user-menu em {
    padding: 0 0 0 10px;
  }

  .profile-list .column {
    width: 100%;
  }
  /* .block-album .images span.item,
  .block-album .images a.item {
    width: calc(50% - 2rem);
    padding-bottom: 62.1%;
  } */
  .block-album .images {
    padding: 0 1rem;
  }
  .block-channel .img {
    width: 100%;
    padding: 0;
    margin: 0 0 1rem;
  }
  .block-channel .img .channel-user {
    right: 5px;
  }
  .block-channel .channel-rating strong {
    font-size: 6.5rem;
    padding: 2rem 1rem;
    border-radius: 1rem;
  }
  .block-model .img {
    float: none;
    display: block;
    margin: 0 auto 1rem;
  }
  .member-menu + .main-container-user .item,
  .member-menu + .main-container-user .list-videos-screenshots .item,
  .member-menu + .main-container-user .list-albums-images .item {
    width: calc((100% - 20px) / 2);
  }
  .block-album .images .message,
  .block-video .no-player .message {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .block-album form .submit,
  .block-video .no-player form .submit {
    font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  form .date .selectbox {
    margin-right: 5px;
  }
  form .captcha-control .image {
    margin: 0 0 1.5rem;
  }
  form .captcha-control .image img {
    float: inherit;
  }
  form .captcha-control .image .textfield {
    width: 100%;
  }
  form .list-selector-popup .button-group .item {
     width: 45%;
  }
  .sidebar + .main-container .list-videos .item,
  .sidebar + .main-container .place ~ .item:nth-of-type(-n+7),
  .place ~ .item:nth-of-type(-n+7),
  .member-menu + .main-container-user .list-videos .item,
  .member-menu + .main-container-user .list-playlists .item,
  .list-playlists .item,
  .list-videos .item {
    width: calc(100% - 5px);
    margin: 5px 0 0 5px;
  }
  .list-albums .item,
  .sidebar + .main-container .list-albums .place ~ .item:nth-of-type(-n + 7) {
    width: calc(50% - 5px);
    margin: 5px 0 0 5px;
  }
  .member-menu + .main-container-user .list-albums .item .title,
  .main-container .list-albums .item .title {
    font-size: 12px;
  }
  .member-menu + .main-container-user .list-albums .item .wrap,
  .main-container .list-albums .item .wrap {
    font-size: 11px;
  }
  .member-menu + .main-container-user .list-albums .item .wrap div:before,
  .main-container .list-albums .item .wrap div:before {
    font-size: 13px;
  }
  .member-menu + .main-container-user .list-albums .item .wrap .photos,
  .main-container .list-albums .item .wrap .photos {
    padding-left: 21px;
  }
  .member-menu + .main-container-user .list-albums .item .wrap .views,
  .main-container .list-albums .item .wrap .views {
    padding-left: 23px;
  }
  .item-control {
    padding: 4px 7px;
  }
  .member-menu + .main-container-user .list-albums .item .wrap div,
  .main-container .list-albums .item .wrap div {
    padding-left: 18px;
  }
  .popup-holder {
    min-width: 280px;
  }
  .navigation .primary {
    max-width: 100%;
  }
  .sidebar .comments li,
  .sidebar .list li,
  .sidebar .filter li {
    width: 100%;
  }
  
  .block-channel .channel-rating strong {
    font-size: 5.5rem;
    padding: 1rem 1rem;
  }
  .block-model .img {
    width: 100%;
    height: auto;
    padding-bottom: 64.2%;
  }
  .block-video .playlist .item {
    width: calc(100% - 10px);
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .sort,
  .content {
    cursor: pointer;
  }
}